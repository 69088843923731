export const austrianStates = [
  { id: "Burgenland", name: "Burgenland" },
  { id: "Kärnten", name: "Kärnten" },
  { id: "Niederösterreich", name: "Niederösterreich" },
  { id: "Oberösterreich", name: "Oberösterreich" },
  { id: "Salzburg", name: "Salzburg" },
  { id: "Steiermark", name: "Steiermark" },
  { id: "Tirol", name: "Tirol" },
  { id: "Vorarlberg", name: "Vorarlberg" },
  { id: "Wien", name: "Wien" }
];

export const germanStates = [
  { id: "Baden-Württemberg", name: "Baden-Württemberg" },
  { id: "Bayern", name: "Bayern" },
  { id: "Berlin", name: "Berlin" },
  { id: "Brandenburg", name: "Brandenburg" },
  { id: "Bremen", name: "Bremen" },
  { id: "Hamburg", name: "Hamburg" },
  { id: "Hessen", name: "Hessen" },
  { id: "Mecklenburg-Vorpommern", name: "Mecklenburg-Vorpommern" },
  { id: "Niedersachsen", name: "Niedersachsen" },
  { id: "Nordrhein-Westfalen", name: "Nordrhein-Westfalen" },
  { id: "Rheinland-Pfalz", name: "Rheinland-Pfalz" },
  { id: "Saarland", name: "Saarland" },
  { id: "Sachsen-Anhalt", name: "Sachsen-Anhalt" },
  { id: "Sachsen", name: "Sachsen" },
  { id: "Schleswig-Holstein", name: "Schleswig-Holstein" },
  { id: "Sachsen-Anhalt", name: "Sachsen-Anhalt" },
  { id: "Sachsen", name: "Sachsen" },
  { id: "Tühringen", name: "Tühringen" }
];
