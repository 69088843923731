import React from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  EditButton,
  TextInput,
  NullableBooleanInput,
  ReferenceInput,
  SelectInput,
  translate,
  FunctionField,
  Filter,
  Pagination,
  CreateButton,
  CardActions,
  Button
} from "react-admin";
import { Redirect } from "react-router-dom";
import { push } from "react-router-redux";
import BlockButton from "../../components/BlockButton";
import VerifyButton from "../../components/VerifyButton";
import BlockUserPopup from "../../components/BlockUserPopup";
import VerifyUserPopup from "../../components/VerifyUserPopup";
import Icon from "@material-ui/icons/LocalTaxi";
import { setPopup, togglePopup } from "../../reducer/flagActions";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import {
  updateUserActive,
  updateUserEnabled,
  deleteUser,
  deactivateUnpaidUsers
} from "../../utils/restUtils";
import { UserFlagsChips } from "../../components/MiscChips";
import DeleteButton from "../../components/DeleteButton";
import DeletePopup from "../../components/DeletePopup";

import { setError } from "../../reducer/errorActions";
import { noPaymentRowStyle, isClubAdmin } from "../../utils/utils";
import { WithPermissions } from "ra-core";
import PageTitle from "../../components/PageTitle";
import {
  rowsPerPageOptions,
  defaultRowsPerPageOption
} from "../../utils/paging";
import moment from "moment";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import BlockPopup from "../../components/BlockPopup";
import BlockIcon from "@material-ui/icons/Cancel";
import CopyIcon from "@material-ui/icons/ContentPaste";

moment.locale("de-at");

export const UserIcon = Icon;
const UserRoleOptionRenderer = translate(({ translate, record }) => (
  <span>{translate(record.name)}</span>
));

const UserRoleListRenderer = translate(({ translate, record }) => {
  return (
    <span style={{ display: "block", marginRight: "3px" }}>
      {translate(record.name)}
    </span>
  );
});

const ClubListRenderer = ({ record }) => (
  <span style={{ display: "block", marginRight: "3px" }}>{record.name}</span>
);

const WithProps = ({ children, ...props }) => children(props);

const styles = {
  blockUnpaidBtn: {
    justifyContent: "center",
    color: "#E8AC61"
  }
};

const UserFilter = props => {
  return (
    <Filter {...props}>
      <ReferenceInput
        alwaysOn
        source="clubId"
        reference="clubs"
        perPage={100}
        sort={{ field: "name", order: "ASC" }}
      >
        <SelectInput optionText={<ClubListRenderer />} optionValue="id" />
      </ReferenceInput>
      <TextInput alwaysOn elStyle={{ width: 380 }} source="username" />
      <ReferenceInput
        alwaysOn
        source="userRolePk"
        reference="userRoles"
        perPage={100}
        sort={{ field: "name", order: "ASC" }}
      >
        <SelectInput optionText={<UserRoleOptionRenderer />} optionValue="id" />
      </ReferenceInput>
      <TextInput alwaysOn source="firstName" />
      <TextInput alwaysOn source="lastName" />
      <NullableBooleanInput
        alwaysOn
        source="active"
        options={{ defaultToggled: true }}
      />
      <NullableBooleanInput
        style={{ width: "200px" }}
        alwaysOn
        source="notPaid"
        options={{ defaultToggled: true }}
      />
    </Filter>
  );
};

const mapStateToProps = state => ({
  blockUserPopupOpen: state.flags.popupOpen.popupBlockUser,
  blockUnpaidUserPopupOpen: state.flags.popupOpen.popupBlockUnpaidUser,
  blockUserData: state.flags.data.popupBlockUser,
  verifyUserPopupOpen: state.flags.popupOpen.popupVerifyUser,
  verifyUserData: state.flags.data.popupVerifyUser,
  deletePopupOpen: state.flags.popupOpen.popupDelete,
  deleteData: state.flags.data.popupDelete,
  httpErrorMsg: state.error.httpError.message
});

const mapDispatchToProps = dispatch => ({
  push,
  dispatch
});

export const UserList = translate(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(props => {
    const doDelete = () => {
      let data = props.deleteData;
      deleteUser(data).then(
        data => {
          props.dispatch(togglePopup("popupDelete"));
          props.dispatch(push("/users_"));
          props.dispatch(push("/users"));
          //props.history.push("/users");
          props.dispatch(setError(null));
        },
        e => {
          props.dispatch(setError(e));
        }
      );
    };

    const doBlockUser = doBlock => {
      let data = { id: props.blockUserData.id, active: !doBlock };
      updateUserActive(data).then(
        data => {
          props.dispatch(togglePopup("popupBlockUser"));
          props.dispatch(push("/users_"));
          props.dispatch(push("/users"));
          props.dispatch(setError(null));
        },
        e => {
          props.dispatch(setError(e));
        }
      );
    };

    const doBlockUnpaidUsers = () => {
      deactivateUnpaidUsers().then(
        data => {
          props.dispatch(togglePopup("popupBlockUnpaidUser"));
          props.dispatch(push("/users_"));
          props.dispatch(push("/users"));
          props.dispatch(setError(null));
        },
        e => {
          props.dispatch(setError(e));
        }
      );
    };

    const doVerifyUser = doVerify => {
      let data = { id: props.verifyUserData.id, enabled: doVerify };
      updateUserEnabled(data).then(
        data => {
          props.dispatch(togglePopup("popupVerifyUser"));
          props.dispatch(push("/users_"));
          props.dispatch(push("/users"));
          //props.history.push("/users");
          props.dispatch(setError(null));
        },
        e => {
          props.dispatch(setError(e));
        }
      );
    };

    const copyEmail = user => {
      if (user && user.email) navigator.clipboard.writeText(user.email);
    };

    const cardActionStyle = {
      zIndex: 2,
      display: "inline-block",
      float: "right"
    };

    const Actions = ({ permissions, ...props }) => {
      return (
        <CardActions style={cardActionStyle}>
          {permissions &&
          (permissions.indexOf("IS_CLIENT_ADMIN") >= 0 ||
            permissions.indexOf("IS_PORTAL_ADMIN") >= 0 ||
            permissions.indexOf("IS_ADMIN") >= 0) ? (
            <CreateButton basePath={props.basePath} />
          ) : null}
          {permissions &&
          (permissions.indexOf("IS_CLIENT_ADMIN") >= 0 ||
            permissions.indexOf("IS_PORTAL_ADMIN") >= 0 ||
            permissions.indexOf("IS_ADMIN") >= 0) ? (
            <IconButton
              onClick={() =>
                props.dispatch(togglePopup("popupBlockUnpaidUser"))
              }
              style={styles.blockUnpaidBtn}
              {...props}
            >
              <BlockIcon />
            </IconButton>
          ) : null}
        </CardActions>
      );
    };

    return (
      <Grid>
        <PageTitle
          title={props.translate("resources.users.listPage.headline")}
        />
        <WithPermissions
          render={({ permissions }) =>
            isClubAdmin(permissions) && <Redirect to={"/dashboard"} />
          }
        />
        <List
          {...props}
          bulkActionButtons={false}
          filters={<UserFilter />}
          exporter={false}
          sort={{ field: "lastName", order: "ASC" }}
          pagination={
            <Pagination rowsPerPageOptions={rowsPerPageOptions} {...props} />
          }
          perPage={defaultRowsPerPageOption}
          filterDefaultValues={{ userRolePk: 4 }} //userRolePk 4 => shooters
          actions={<Actions {...props} />}
        >
          <Datagrid rowStyle={noPaymentRowStyle}>
            <TextField source="username" />
            <FunctionField
              label="resources.users.fields.userRoles"
              render={record =>
                typeof record.userRoles !== "undefined" &&
                record.userRoles.map((role, index) =>
                  role.deleted ? null : (
                    <UserRoleListRenderer key={role.id} record={role} />
                  )
                )
              }
            />
            <TextField source="club.name" />
            <TextField source="firstName" />
            <TextField source="lastName" />
            <DateField source="createdAt" locales="de-DE" />
            <DateField
              source="paymentUntil"
              locales="de-DE"
              label={props.translate(
                "resources.clubs.listPage.filters.paymentUntilTo"
              )}
            />
            <FunctionField
              render={record => (
                <UserFlagsChips
                  active={record.active}
                  enabled={record.enabled}
                  translate={props.translate}
                />
              )}
            />
            <EditButton label="" />
            <FunctionField
              render={record => (
                <div>
                  <IconButton onClick={() => copyEmail(record)}>
                    <CopyIcon />
                  </IconButton>
                  <BlockButton
                    itemId={record.id}
                    blockHandler={() =>
                      props.dispatch(
                        togglePopup("popupBlockUser", {
                          id: record.id,
                          isBlocked: record.active ? false : true
                        })
                      )
                    }
                    isBlocked={record.active ? false : true}
                  />
                  <DeleteButton
                    userId={record.id}
                    handleDelete={() =>
                      props.dispatch(
                        togglePopup("popupDelete", {
                          id: record.id
                        })
                      )
                    }
                  />
                  <VerifyButton
                    itemId={record.id}
                    clickHandler={() =>
                      props.dispatch(
                        togglePopup("popupVerifyUser", {
                          id: record.id,
                          isVerified: record.enabled ? true : false
                        })
                      )
                    }
                    isVerified={record.enabled ? true : false}
                  />
                </div>
              )}
            />
          </Datagrid>
        </List>
        <BlockUserPopup
          open={props.blockUserPopupOpen}
          handleClose={() => props.dispatch(togglePopup("popupBlockUser"))}
          handleBlock={() =>
            doBlockUser(
              props.blockUserData ? props.blockUserData.isBlocked : false
            )
          }
          errorMessage={props.httpErrorMsg}
          isBlocked={
            props.blockUserData ? props.blockUserData.isBlocked : false
          }
        />
        <BlockPopup
          resource="unpaidUsers"
          open={props.blockUnpaidUserPopupOpen}
          handleClose={() =>
            props.dispatch(togglePopup("popupBlockUnpaidUser"))
          }
          handleBlock={() => doBlockUnpaidUsers()}
          errorMessage={props.httpErrorMsg}
          isBlocked={false}
        />
        <VerifyUserPopup
          open={props.verifyUserPopupOpen}
          handleClose={() => props.dispatch(togglePopup("popupVerifyUser"))}
          handleClick={() =>
            doVerifyUser(
              props.verifyUserData ? props.verifyUserData.isVerified : false
            )
          }
          errorMessage={props.httpErrorMsg}
          isVerified={
            props.verifyUserData ? props.verifyUserData.isVerified : false
          }
        />
        <DeletePopup
          open={props.deletePopupOpen}
          handleClose={() => props.dispatch(togglePopup("popupDelete"))}
          handleDelete={() => doDelete()}
          errorMessage={props.httpErrorMsg}
        />
      </Grid>
    );
  })
);
