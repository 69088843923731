import React, { Fragment } from "react";
import {
  AutocompleteInput,
  CreateButton,
  Datagrid,
  DateField,
  EditButton,
  ExportButton,
  Filter,
  FunctionField,
  List,
  NullableBooleanInput,
  NumberField,
  Pagination,
  ReferenceInput,
  TextField,
  TextInput,
  translate,
  WithPermissions
} from "react-admin";
import { push } from "react-router-redux";
import {
  isAdmin,
  isClubAdmin,
  isPortalAdmin,
  isShooter
} from "../../utils/utils";
import CancelButton from "../../components/CancelButton";
import CancelCompetitionPopup from "../../components/CancelCompetitionPopup";
import CopyButton from "../../components/CopyButton";
import { togglePopup } from "../../reducer/flagActions";
import { connect } from "react-redux";
import {
  exportallCompetitors,
  getClub,
  payCompetition,
  updateCompetitionCancel
} from "../../utils/restUtils";
import { setError } from "../../reducer/errorActions";
import Grid from "@material-ui/core/Grid";
import PageTitle from "../../components/PageTitle";
import { DateInput } from "react-admin-date-inputs";
import LinkIcon from "@material-ui/icons/Link";
import { IconButton } from "@material-ui/core";
import download from "downloadjs";
import {
  defaultRowsPerPageOption,
  rowsPerPageOptions
} from "../../utils/paging";
import PopupBulkDeleteButton from "../../components/PopupBulkDeleteButton";
import { setSuccess } from "../../reducer/successActions";
import ReceiptIcon from "@material-ui/icons/Receipt";
import CheckIcon from "@material-ui/icons/Check";
import InfoIcon from "@material-ui/icons/Info";
import CompetitionInvoicePopup from "../../components/CompetitionInvoicePopup";
import GenericPopup from "../../components/GenericPopup";
import PopupBulkEventButton from "../../components/PopupBulkEventButton";

const createFilename = name => {
  return (
    name +
    "-" +
    new Date().getFullYear() +
    "-" +
    (new Date().getMonth() + 1) +
    "-" +
    new Date().getDate() +
    "_" +
    new Date().getHours() +
    "_" +
    new Date().getMinutes() +
    "_" +
    new Date().getSeconds() +
    ".xlsx"
  );
};

const exportallCompetitorsCall = event => {
  exportallCompetitors()
    .then(response => {
      return response.blob();
    })
    .then(
      data => {
        download(
          data,
          createFilename("bewerbe"),
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        );
      },
      e => {
        this.props.dispatch(setError(e));
      }
    );
};

const CompetitionFilter = translate(props => (
  <WithPermissions
    render={({ permissions }) => (
      <Filter {...props}>
        {(isAdmin(permissions) || isPortalAdmin(permissions)) && (
          <ReferenceInput
            alwaysOn
            source="clubId"
            reference="clubs"
            filter={{ active: true }}
            perPage={100}
            sort={{ field: "name", order: "ASC" }}
            label="resources.competitions.listPage.filters.club"
          >
            <AutocompleteInput
              optionValue="id"
              options={{
                suggestionsContainerProps: {
                  modifiers: { keepTogether: { enabled: true } }
                }
              }}
            />
          </ReferenceInput>
        )}
        <TextInput alwaysOn source="name" />
        <DateInput
          alwaysOn
          label={props.translate(
            "resources.competitions.listPage.filters.dateFrom"
          )}
          source="dateFrom"
          options={{
            format: "dd.MM.YYYY",
            ampm: false,
            clearable: true
          }}
        />
        <DateInput
          alwaysOn
          label={props.translate(
            "resources.competitions.listPage.filters.dateTo"
          )}
          source="dateTo"
          options={{
            format: "dd.MM.YYYY",
            ampm: false,
            clearable: true
          }}
        />
        {(isAdmin(permissions) || isPortalAdmin(permissions)) && (
          <NullableBooleanInput
            style={{ width: "200px" }}
            label={props.translate(
              "resources.competitions.listFilter.paidFilter"
            )}
            source="notPaid"
            alwaysOn
          />
        )}
      </Filter>
    )}
  />
));

const mapStateToProps = state => ({
  cancelCompetitionPopupOpen: state.flags.popupOpen.popupCancelCompetition,
  cancelCompetitionData: state.flags.data.popupCancelCompetition,
  competitionInvoicePopupOpen: state.flags.popupOpen.popupCompetitionInvoice,
  competitionInvoiceData: state.flags.data.popupCompetitionInvoice,
  competitionPayPopupOpen: state.flags.popupOpen.popupCompetitionPay,
  competitionPayData: state.flags.data.popupCompetitionPay,
  httpErrorMsg: state.error.httpError.message
});

const mapDispatchToProps = dispatch => ({
  push,
  dispatch
});

const CompetitionActions = ({
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  total,
  permissions
}) => (
  <div>
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: "button"
      })}
    <CreateButton basePath={basePath} />
    {isClubAdmin(permissions) ? (
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filter={filterValues}
        exporter={null}
        onClick={() => exportallCompetitorsCall()}
      />
    ) : null}
  </div>
);

export const EventList = connect(
  mapStateToProps,
  mapDispatchToProps
)(
  translate(props => {
    if (props.permissions && isShooter(props.permissions)) {
      console.log("Shooter", props.permissions);
      props.dispatch(push("/competitionList"));
    }

    const doCancelCompetition = doCancel => {
      let data = { id: props.cancelCompetitionData.id, cancelled: !doCancel };
      updateCompetitionCancel(data).then(
        data => {
          props.dispatch(togglePopup("popupCancelCompetition"));
          props.dispatch(push("/events_"));
          props.dispatch(push("/events"));
          //props.history.push("/users");
          props.dispatch(setError(null));
        },
        e => {
          props.dispatch(setError(e));
        }
      );
    };

    const invoiceClosed = () => {
      setTimeout(() => {
        props.dispatch(togglePopup("popupCompetitionInvoice"));
        props.dispatch(push("/events_"));
        props.dispatch(push("/events"));
        //props.history.push("/users");
        props.dispatch(setError(null));
      }, 200);
    };

    const handlePaid = () => {
      payCompetition(
        props.competitionPayData.id,
        !props.competitionPayData.paid
      ).then(() => {
        props.dispatch(
          setSuccess({
            message: "resources.competitions.listPage.paidSuccess"
          })
        );
        props.dispatch(togglePopup("popupCompetitionPay"));
        props.dispatch(push("/events_"));
        props.dispatch(push("/events"));
      });
    };

    const copy = require("clipboard-copy");
    const currentUrl = window.location;
    const basePath =
      currentUrl.protocol +
      "//" +
      currentUrl.host +
      "/" +
      currentUrl.pathname.split("/")[1];

    const PostPagination = props => (
      <Pagination rowsPerPageOptions={rowsPerPageOptions} {...props} />
    );

    const adminRowStyle = record => {
      if (record.active)
        return {
          backgroundColor: record.paid ? "white" : "#ff6666"
        };
      else
        return {
          backgroundColor: record.paid ? "lightgrey" : "#aa3333",
          textDecoration: "line-through"
        };
    };

    const toggleInvoice = (props, competition) => {
      getClub(competition.club.id).then(club => {
        competition.club = club;
        props.dispatch(togglePopup("popupCompetitionInvoice", competition));
      });
    };

    const togglePay = (props, competition) => {
      props.dispatch(togglePopup("popupCompetitionPay", competition));
    };

    return (
      <WithPermissions
        render={({ permissions }) => (
          <Fragment>
            <Grid>
              <PageTitle
                title={props.translate(
                  isClubAdmin(permissions)
                    ? "eventList.titleClubAdmin"
                    : "eventList.titleAdmin"
                )}
              />
              <List
                {...props}
                perPage={defaultRowsPerPageOption}
                pagination={<PostPagination />}
                filters={<CompetitionFilter />}
                bulkActionButtons={
                  isAdmin(permissions) ? (
                    <PopupBulkEventButton />
                  ) : (
                    <PopupBulkDeleteButton />
                  )
                }
                exporter={false}
                actions={<CompetitionActions permissions={permissions} />}
              >
                <Datagrid
                  rowStyle={
                    isAdmin(permissions) || isPortalAdmin(permissions)
                      ? adminRowStyle
                      : null
                  }
                >
                  {isAdmin(permissions) ? (
                    <TextField
                      label="resources.events.fields.club"
                      source="club.name"
                    />
                  ) : null}
                  <TextField
                    label="resources.events.fields.eventName"
                    source="name"
                  />

                  <FunctionField
                    source="publicLink"
                    render={record => (
                      <Fragment>
                        <span>{record.publicLink}</span>
                        <IconButton
                          onClick={e =>
                            copy(
                              basePath +
                                "#/competitionDetails/" +
                                record.publicLink
                            )
                          }
                        >
                          <LinkIcon />
                        </IconButton>
                      </Fragment>
                    )}
                  />

                  <DateField
                    locales="de-DE"
                    source="competitionDate"
                    showTime
                  />
                  <NumberField
                    source="nominationFee"
                    locales="de-DE"
                    options={{ style: "currency", currency: "EUR" }}
                  />

                  <DateField
                    locales="de-DE"
                    source="registrationStart"
                    showTime
                  />
                  <DateField
                    locales="de-DE"
                    source="registrationEnd"
                    showTime
                  />

                  <FunctionField
                    render={record => [
                      <CancelButton
                        itemId={record.id}
                        cancelHandler={() =>
                          props.dispatch(
                            togglePopup("popupCancelCompetition", {
                              id: record.id,
                              isCancelled: record.cancelled ? true : false
                            })
                          )
                        }
                        isCancelled={record.cancelled ? true : false}
                      />,
                      <CopyButton
                        itemId={record.id}
                        clickHandler={() =>
                          props.dispatch(push("/events/copy/" + record.id))
                        }
                        isCancelled={record.cancelled ? true : false}
                      />
                    ]}
                  />
                  <EditButton label="" />
                  {(isAdmin(permissions) || isPortalAdmin(permissions)) && (
                    <FunctionField
                      render={record => [
                        <IconButton
                          onClick={() => {
                            toggleInvoice(props, record);
                          }}
                          style={{ width: "24px" }}
                        >
                          <ReceiptIcon />
                        </IconButton>,
                        <IconButton
                          onClick={() => {
                            togglePay(props, record);
                          }}
                          style={{ width: "24px" }}
                        >
                          <CheckIcon />
                        </IconButton>
                      ]}
                    />
                  )}
                </Datagrid>
              </List>
              <CancelCompetitionPopup
                open={props.cancelCompetitionPopupOpen}
                handleClose={() => {
                  props.dispatch(togglePopup("popupCancelCompetition"));
                  props.dispatch(setError(null));
                }}
                handleCancel={() =>
                  doCancelCompetition(props.cancelCompetitionData.isCancelled)
                }
                isCancelled={
                  props.cancelCompetitionData
                    ? props.cancelCompetitionData.isCancelled
                    : false
                }
                errorMessage={props.httpErrorMsg}
              />
              <CompetitionInvoicePopup
                open={props.competitionInvoicePopupOpen}
                handleClose={() => invoiceClosed()}
                competition={props.competitionInvoiceData}
                errorMessage={props.httpErrorMsg}
              />
              <GenericPopup
                open={props.competitionPayPopupOpen}
                handleConfirm={() => handlePaid()}
                handleClose={() => {
                  props.dispatch(togglePopup("popupCompetitionPay"));
                }}
                message={props.translate(
                  "resources.orderList.payOrderItemPopup.warning"
                )}
                title={props.translate(
                  props.competitionPayData && props.competitionPayData.paid
                    ? "resources.orderList.unPayOrderItemPopup.title"
                    : "resources.orderList.payOrderItemPopup.title"
                )}
                confirmText={props.translate(
                  "resources.orderList.payOrderItemPopup.confirm"
                )}
                cancelText={props.translate(
                  "resources.orderList.payOrderItemPopup.cancel"
                )}
              />
            </Grid>
          </Fragment>
        )}
      />
    );
  })
);
