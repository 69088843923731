// React imports
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
// Material imports
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
// Other imports
import DynamicList from "../DynamicList";
import ErrorMessage from "../ErrorMessage";
import { isAdmin, isClubAdmin } from "../../utils/utils";
import GenericPopup from "../GenericPopup";
import SuccessMessage from "../SuccessMessage";
import { ExportButton } from "react-admin";
import { Button, CircularProgress } from "@material-ui/core";
import { rowsPerPageOptions } from "../../utils/paging";
import DialogContent from "@material-ui/core/DialogContent";
import CompetitionSignupWizard from "./CompetitionSignupWizard";
import Dialog from "@material-ui/core/Dialog";
import { togglePopup } from "../../reducer/flagActions";
import { completeOrder, createOrder } from "../../utils/restUtils";
import { setError } from "../../reducer/errorActions";
import { setSuccess } from "../../reducer/successActions";

const styles = {
  modalWrapper: {
    position: "absolute",
    width: "350px",
    padding: 0,
    backgroundColor: "#ffffff",
    overflow: "auto"
  },
  dialogWrapper: {
    minWidth: "400px",
    paddingTop: "0px",
    paddingBottom: "5px"
  },
  closeBtn: {
    textAlign: "end"
  },
  signUpBtn: {
    color: "#ffffff",
    backgroundColor: "#4F6982"
  }
};

const OrderItemList = props => {
  const unCancelled = props.unCancelled;
  const unPaid = props.unPaid;
  const secondPay = props.secondPay;

  let disableKeys = [
    "order",
    "cancelled",
    "competitionNominationFee",
    "competitionPayOnSite",
    "competitionPaymentViaDeposit",
    "multipleWaitingListUuid",
    "multipleWaitingList",
    "squadSize",
    "squadSubscriptionSize",
    "orderPaymentMethod",
    "factorName",
    "initialPaymentDate",
    "squadStartTime",
    "squadEndTime",
    "squadDate",
    "competitionPaymentWithWirecard",
    "competitionEvent",
    "paidState",
    "paid2State",
    "state"
  ];

  let disableSorting = [
    "cancelButton",
    "edit",
    "waitingList",
    "change",
    "userId",
    "squadStandName"
  ];

  const unPaidStyle = orderItem => {
    return {
      backgroundColor: orderItem.paidState === false ? "#ff6666" : "white"
    };
  };

  if (!isAdmin(props.permissions)) {
    disableKeys.push("id");
    disableKeys.push("userId");
    disableKeys.push("payButton");
  }

  if (isClubAdmin(props.permissions)) {
    disableKeys.push("clubName");
  }

  props.orderItems.forEach(function(part, index, theArray) {
    let country = props.orderItems[index].country;
    props.orderItems[index].country = null;
    if (country && (typeof country === "string" || country instanceof String)) {
      props.orderItems[index].country = (
        <img
          src={window.location.origin + "/images/countries/" + country + ".png"}
          width="25"
          country={country}
        />
      );
    } else if (country && country.props && country.props.country) {
      props.orderItems[index].country = (
        <img
          src={
            window.location.origin +
            "/images/countries/" +
            country.props.country +
            ".png"
          }
          width="25"
          country={country.props.country}
        />
      );
    }
  });

  const toggleCompetitionRegistrationForm = () => {
    props.dispatch(togglePopup("popupCompetitionRegistrationForm"));
  };

  const reserveOrder = (token, order) => {
    if (!!order.id && !!token) {
      completeOrder(token, order).then(
        () => {
          props.dispatch(setError(null));
          props.dispatch(togglePopup("popupCompetitionRegistrationForm"));
          props.dispatch(
            setSuccess({ message: "resources.orderItemList.ORDER_SUBMITTED" })
          );
          props.handleRefresh();
        },
        e => props.dispatch(setError(e))
      );
    } else {
      createOrder(order).then(
        () => {
          props.dispatch(setError(null));
          props.dispatch(togglePopup("popupCompetitionRegistrationForm"));
          props.dispatch(
            setSuccess({ message: "resources.orderItemList.ORDER_SUBMITTED" })
          );
          props.handleRefresh();
        },
        e => props.dispatch(setError(e))
      );
    }
  };

  return (
    <div>
      <Dialog
        open={props.popupCompetitionRegistrationForm}
        onClose={toggleCompetitionRegistrationForm}
      >
        <DialogContent style={styles.dialogWrapper}>
          <CompetitionSignupWizard
            clubs={props.clubs}
            identity={props.identity}
            permissions={props.permissions}
            users={props.users}
            errorMessage={props.errorMessage}
            onClose={toggleCompetitionRegistrationForm}
            translate={props.translate}
            signup={reserveOrder}
          />
        </DialogContent>
      </Dialog>
      <Grid container spacing={16}>
        <Grid item xs={12}>
          <Card>
            <GenericPopup
              open={props.cancelOrderItemPopup}
              handleConfirm={props.handleCancel}
              handleClose={props.handleCancelClose}
              message={props.translate(
                unCancelled
                  ? "resources.orderList.unCancelOrderItemPopup.warning"
                  : "resources.orderList.cancelOrderItemPopup.warning"
              )}
              title={props.translate(
                unCancelled
                  ? "resources.orderList.unCancelOrderItemPopup.title"
                  : "resources.orderList.cancelOrderItemPopup.title"
              )}
              confirmText={props.translate(
                "resources.orderList.cancelOrderItemPopup.confirm"
              )}
              cancelText={props.translate(
                "resources.orderList.cancelOrderItemPopup.cancel"
              )}
            />
            <GenericPopup
              open={props.payOrderItemPopup}
              handleConfirm={props.handlePay}
              handleClose={props.handlePayClose}
              message={props.translate(
                secondPay
                  ? "resources.orderList.payOrderItemPopup.warning2"
                  : "resources.orderList.payOrderItemPopup.warning"
              )}
              title={props.translate(
                unPaid
                  ? "resources.orderList.unPayOrderItemPopup.title"
                  : "resources.orderList.payOrderItemPopup.title"
              )}
              confirmText={props.translate(
                "resources.orderList.payOrderItemPopup.confirm"
              )}
              cancelText={props.translate(
                "resources.orderList.payOrderItemPopup.cancel"
              )}
            />
            <CardContent>
              <div style={{ float: "right" }}>
                <Button
                  onClick={() => {
                    toggleCompetitionRegistrationForm();
                  }}
                  style={styles.signUpBtn}
                >
                  {props.translate("resources.competitionshow.signUp")}
                </Button>
                {props.loading ? (
                  <ExportButton
                    style={{
                      float: "right"
                    }}
                    onClick={props.handleExport}
                    icon={<CircularProgress size={14} />}
                  />
                ) : (
                  <ExportButton
                    style={{ float: "right" }}
                    onClick={props.handleExport}
                  />
                )}
              </div>

              <DynamicList
                refreshing={props.refreshing}
                handleRefresh={props.handleRefresh}
                filterValues={props.filterValues}
                data={props.orderItems}
                disable_sorting={disableSorting}
                page={props.page}
                rowsPerPage={props.rowsPerPage}
                total={props.total}
                handleChangePage={props.handleChangePage}
                handleChangeRowsPerPage={props.handleChangePage}
                translate={props.translate}
                sortCallback={props.sortCallback}
                disable_keys={disableKeys}
                translationPrefix={props.translationPrefix}
                dateParseString={props.dateParseString}
                momentFormat={props.momentFormat}
                locale={props.locale}
                options={{ filterMenu: true }}
                handleFilter={props.handleFilter}
                transpose={false}
                filterList={[
                  { name: "competitionName", type: String },
                  { name: "clubName", type: String },
                  { name: "lastName", type: String },
                  { name: "firstName", type: String },
                  { name: "email", type: String },
                  { name: "competitionCompetitionDate", type: Date },
                  { name: "fromDate", type: Date },
                  { name: "toDate", type: Date },
                  { name: "paid", type: Boolean },
                  { name: "isRegistered", type: Boolean },
                  { name: "squadName", type: String }
                ]}
                columnDateFormat={{ competitionCompetitionDate: "L" }}
                rowsPerPageOptions={rowsPerPageOptions}
                rowStyle={unPaidStyle}
              />
            </CardContent>
          </Card>
        </Grid>
        <SuccessMessage
          successMessage={props.successMessage}
          open={!!props.successMessage}
          onClose={props.hideSuccess}
          translate={props.translate}
        />
        <ErrorMessage
          errorMessage={props.errorMessage}
          open={!!props.errorMessage}
          onClose={props.hideError}
          translate={props.translate}
        />
      </Grid>
    </div>
  );
};
OrderItemList.propTypes = {
  orderItems: PropTypes.array.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  handleFilter: PropTypes.func.isRequired,
  sortCallback: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  errorMessage: PropTypes.object,
  hideError: PropTypes.func.isRequired,
  permissions: PropTypes.array.isRequired,
  identity: PropTypes.any
};

function mapStateToProps(state) {
  return {
    payOrderItemPopup: state.flags.popupOpen.payOrderItemPopup,
    cancelOrderItemPopup: state.flags.popupOpen.cancelOrderItemPopup,
    popupCompetitionRegistrationForm:
      state.flags.popupOpen.popupCompetitionRegistrationForm
  };
}

export default connect(mapStateToProps)(OrderItemList);
