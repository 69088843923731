import PropTypes from 'prop-types'
import React, { Component } from 'react'
import MultipleSelect from './inputs/MultipleSelect'
import { getClubs, getCompetitionTypes, getLevels } from '../utils/restUtils'
import { Button, Grid } from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear'
import { translate } from 'react-admin'
import { austrianStates } from '../utils/states'

class CompetitionListFilter extends Component {
  state = {
    filters: {
      club: [],
      type: [],
      level: [],
      province: []
    },
    clubOptions: [],
    typeOptions: [],
    levelOptions: [],
    provinceOptions: []
  };

  componentDidMount = () => {
    this.setState({ typeOptions: this.generateTypes() });
    this.setState({ levelOptions: this.generateLevels() });
    this.setState({ clubOptions: this.generateClubs() });
    this.setState({ provinceOptions: this.generateProvinces() });
    this.resetSavedFilters();
  };

  resetSavedFilters = () => {
    const savedFiltersAsString = localStorage.getItem("competitionFilters");
    if (savedFiltersAsString && savedFiltersAsString !== "null")
      this.setState({ filters: JSON.parse(savedFiltersAsString) }, () => {
        this.props.handleFilterChange(this.state.filters);
      });
    else this.props.handleFilterChange(this.state.filters);
  };

  generateClubs = () => {
    let clubs = [];
    getClubs().then(data => {
      for (let i = 0; i < data.length; i++) {
        clubs.push({
          label: data[i].name,
          value: data[i].id
        });
      }
    });
    return clubs;
  };

  generateProvinces = () => {
    return austrianStates.map(s => {
      return {
        label: s.name,
        value: s.id
      };
    });
  };

  generateLevels = () => {
    let levels = [];
    getLevels(0, 1000, "name", "asc", { deleted: 0 }).then(data => {
      for (let i = 0; i < data.length; i++) {
        levels.push({
          label: data[i].name,
          value: data[i].id
        });
      }
    });
    return levels;
  };

  generateTypes = () => {
    let types = [];
    getCompetitionTypes(0, 1000, "name", "asc", { deleted: 0 }).then(data => {
      for (let i = 0; i < data.length; i++) {
        types.push({
          label: data[i].name,
          value: data[i].id
        });
      }
      types.push({
        label: this.props.translate("resources.competitions.listFilter.events"),
        value: 0
      });
    });

    return types;
  };

  handleChange = event => {
    var filters = this.state.filters;
    filters[event.target.name] = event.target.value;
    this.setState({ filters: filters });
    this.props.handleFilterChange(filters);
    localStorage.setItem("competitionFilters", JSON.stringify(filters));
  };

  handleClearFilters = () => {
    var emptyFilters = {
      club: [],
      type: [],
      level: [],
      province: []
    };
    this.props.handleFilterChange(emptyFilters);
    localStorage.setItem("competitionFilters", JSON.stringify(emptyFilters));
    this.setState({
      filters: emptyFilters
    });
  };

  needClearButton = () => {
    for (const value of Object.values(this.state.filters))
      if (value && value.length > 0) return true;
    return false;
  };

  render() {
    return (
      <Grid container spacing={24}>
        <Grid item xs>
          <MultipleSelect
            name="club"
            value={this.state.filters.club}
            handleChange={this.handleChange}
            options={this.state.clubOptions}
            label={this.props.translate(
              "resources.competitions.listFilter.club"
            )}
          />
        </Grid>
        <Grid item xs>
          <MultipleSelect
            name="type"
            value={this.state.filters.type}
            handleChange={this.handleChange}
            options={this.state.typeOptions}
            label={this.props.translate(
              "resources.competitions.listFilter.matchType"
            )}
          />
        </Grid>
        <Grid item xs>
          <MultipleSelect
            name="level"
            value={this.state.filters.level}
            handleChange={this.handleChange}
            options={this.state.levelOptions}
            label={this.props.translate(
              "resources.competitions.listFilter.level"
            )}
          />
        </Grid>
        <Grid item xs>
          <MultipleSelect
            name="province"
            value={this.state.filters.province}
            handleChange={this.handleChange}
            options={this.state.provinceOptions}
            label={this.props.translate(
              "resources.competitions.listFilter.province"
            )}
          />
        </Grid>
        <Grid item xs>
          {this.needClearButton() && (
            <Button
              variant="outlined"
              size="small"
              onClick={this.handleClearFilters}
              style={{ float: "right" }}
            >
              {this.props.translate(
                "resources.competitions.listFilter.clearFilters"
              )}
              <ClearIcon />
            </Button>
          )}
        </Grid>
      </Grid>
    );
  }
}

CompetitionListFilter.propTypes = {
  handleFilterChange: PropTypes.func.isRequired
};

export default translate(CompetitionListFilter);
