import { CREATE, GET_LIST, GET_MANY, GET_ONE } from "react-admin";
import restClient from "../providers/dataProvider/rest";
import rest from "../providers/dataProvider/rest";

export const getDivisionsFor = competitionTypeId => {
  return new Promise((resolve, reject) => {
    restClient(
      GET_LIST,
      "divisions/findByCompetitionType/" + competitionTypeId,
      {
        pagination: { page: 0, perPage: 200 },
        sort: { field: "", order: "" },
        filter: {}
      }
    )
      .then(data => {
        resolve(data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

export const getReservations = (id, userId) => {
  return new Promise((resolve, reject) => {
    restClient("UPDATE", "reservations/findByCompetitionAndUser/" + id, {
      id: userId
    })
      .then(data => {
        resolve(data);
      })
      .catch(e => {
        reject(e);
      });
  });
};
export const getReservationsByUser = (
  page,
  perPage,
  field,
  order,
  filter,
  id
) => {
  return new Promise((resolve, reject) => {
    restClient(GET_LIST, "reservations/findByUser/" + id, {
      pagination: { page: page + 1, perPage: perPage },
      sort: { field: field, order: order },
      filter: filter
    })
      .then(data => {
        resolve(data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};
export const getUploadTypes = (page, perPage, field, order, filter) => {
  return new Promise((resolve, reject) => {
    restClient(GET_LIST, "uploadTypes", {
      pagination: { page: page + 1, perPage: perPage },
      sort: { field: field, order: order },
      filter: filter
    })
      .then(data => {
        resolve(data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

export const upload = data => {
  return new Promise((resolve, reject) => {
    restClient("UPLOAD", "upload", data)
      .then(data => {
        resolve(data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

export const getNotificationBlocks = data => {
  return new Promise((resolve, reject) => {
    restClient(GET_ONE, "notificationBlock/getForClub", {
      id: data
    })
      .then(data => {
        resolve(data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

export const getCompetitionsPub = (page, perPage, field, order, filter) => {
  return new Promise((resolve, reject) => {
    restClient("GET_LIST_COMPETITION", "publicResources/competitions", {
      pagination: { page: page + 1, perPage: perPage },
      sort: { field: field, order: order },
      filter: filter
    })
      .then(data => {
        resolve(data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

export const getRservationCodes = (page, perPage, field, order, filter) => {
  return new Promise((resolve, reject) => {
    restClient(GET_LIST, "reservationCodes", {
      pagination: { page: page + 1, perPage: perPage },
      sort: { field: field, order: order },
      filter: filter
    })
      .then(data => {
        resolve(data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

export const getOrders = (page, perPage, field, order, filter) => {
  return new Promise((resolve, reject) => {
    restClient(GET_LIST, "orders", {
      pagination: { page: page + 1, perPage: perPage },
      sort: { field: field, order: order },
      filter: filter
    })
      .then(data => {
        resolve(data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

export const getOrderItems = (page, perPage, field, order, filter) => {
  return new Promise((resolve, reject) => {
    restClient(GET_LIST, "orderItems", {
      pagination: { page: page + 1, perPage: perPage },
      sort: { field: field, order: order },
      filter: filter
    })
      .then(data => {
        resolve(data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

export const getClubsPub = (page, perPage, field, order, filter) => {
  return new Promise((resolve, reject) => {
    restClient(GET_LIST, "publicResources/clubsPubList", {
      pagination: { page: page + 1, perPage: perPage },
      sort: { field: field, order: order },
      filter: filter
    })
      .then(data => {
        resolve(data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

export const getCompetitionClasses = filter => {
  return new Promise((resolve, reject) => {
    restClient(GET_LIST, "publicResources/competitionClasses", {
      filter: filter,
      pagination: { page: 0, perPage: 200 },
      sort: { field: "name", order: "asc" }
    })
      .then(data => {
        resolve(data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

export const getCompetitionDevisions = filter => {
  return new Promise((resolve, reject) => {
    restClient(GET_LIST, "publicResources/divisions", {
      pagination: { page: 0, perPage: 200 },
      sort: { field: "name", order: "asc" },
      filter: filter
    })
      .then(data => {
        resolve(data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

export const getCompetitionFactors = filter => {
  return new Promise((resolve, reject) => {
    restClient(GET_LIST, "publicResources/factors", {
      pagination: { page: 0, perPage: 200 },
      sort: { field: "name", order: "asc" },
      filter: filter
    })
      .then(data => {
        resolve(data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

export const getLevels = () => {
  return new Promise((resolve, reject) => {
    restClient(GET_LIST, "publicResources/levels", {
      pagination: { page: 0, perPage: 200 },
      sort: { field: "name", order: "asc" },
      filter: {}
    })
      .then(data => {
        resolve(data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

export const getCompetitionTypes = () => {
  return new Promise((resolve, reject) => {
    restClient(GET_LIST, "publicResources/competitionTypes", {
      pagination: { page: 0, perPage: 200 },
      sort: { field: "", order: "" },
      filter: {}
    })
      .then(data => {
        resolve(data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

export const getCompetitionSquads = filter => {
  return new Promise((resolve, reject) => {
    restClient(GET_LIST, "publicResources/squads", {
      pagination: { page: 0, perPage: 200 },
      sort: { field: "id", order: "asc" },
      filter: filter
    })
      .then(data => {
        resolve(data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

export const getCompetitionSquadStands = filter => {
  return new Promise((resolve, reject) => {
    restClient(GET_LIST, "publicResources/squadStands", {
      pagination: { page: 0, perPage: 200 },
      sort: { field: "id", order: "asc" },
      filter: filter
    })
      .then(data => {
        resolve(data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

export const getClubs = () => {
  return new Promise((resolve, reject) => {
    restClient(GET_LIST, "publicResources/clubs", {
      pagination: { page: 0, perPage: 200 },
      sort: { field: "name", order: "asc" },
      filter: {}
    })
      .then(data => {
        resolve(data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

export const getCompetitionsZips = () => {
  return new Promise((resolve, reject) => {
    restClient(GET_LIST, "publicResources/competitions/zips", {
      pagination: { page: 0, perPage: 200 },
      sort: { field: "zip", order: "asc" },
      filter: {}
    })
      .then(data => {
        resolve(data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

export const getAllClubs = () => {
  return new Promise((resolve, reject) => {
    restClient(GET_LIST, "publicResources/clubs", {
      pagination: { page: 0, perPage: 200 },
      sort: { field: "name", order: "asc" },
      filter: { includeUnlisted: true }
    })
      .then(data => {
        resolve(data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

export const getNotificationTypes = () => {
  return new Promise((resolve, reject) => {
    restClient(GET_LIST, "notificationTypes", {
      pagination: { page: 0, perPage: 200 },
      sort: { field: "", order: "" },
      filter: {}
    })
      .then(data => {
        resolve(data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

export const createPublicUser = data => {
  return new Promise((resolve, reject) => {
    restClient(CREATE, "publicResources/createUser", { data: data })
      .then(data => {
        resolve(data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

export const createReservationCodes = data => {
  return new Promise((resolve, reject) => {
    restClient(CREATE, "reservationCodes", { data: data })
      .then(data => {
        resolve(data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

export const resetPassword = data => {
  return new Promise((resolve, reject) => {
    restClient(CREATE, "publicResources/resetPassword", { data: data })
      .then(data => {
        resolve(data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

export const verifyToken = tokenString => {
  return new Promise((resolve, reject) => {
    restClient(CREATE, "publicResources/verifyToken", {
      data: {
        token: tokenString
      }
    })
      .then(data => {
        resolve(data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

export const getUserClubs = () => {
  return new Promise((resolve, reject) => {
    restClient(GET_LIST, "clubs", {
      pagination: { page: 0, perPage: 100 },
      sort: { field: "", order: "" },
      filter: {}
    })
      .then(data => {
        resolve(data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

export const verifyPasswordResetToken = tokenString => {
  return new Promise((resolve, reject) => {
    restClient(CREATE, "publicResources/confirmPasswordReset", {
      data: {
        token: tokenString
      }
    })
      .then(data => {
        resolve(data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

export const updateUserActive = data => {
  return new Promise((resolve, reject) => {
    restClient("UPDATE", "users/updateActive", {
      id: data.id,
      data: data
    })
      .then(data => {
        resolve(data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

export const updateUserEnabled = data => {
  return new Promise((resolve, reject) => {
    restClient("UPDATE", "users/updateEnabled", {
      id: data.id,
      data: data
    })
      .then(data => {
        resolve(data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

export const updateCompetitionCancel = data => {
  return new Promise((resolve, reject) => {
    restClient("UPDATE", "competitions/updateCancel", {
      id: data.id,
      data: data
    })
      .then(data => {
        resolve(data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

export const updateForceCompetitionCancel = data => {
  return new Promise((resolve, reject) => {
    restClient("UPDATE", "competitions/forceUpdateCancel", {
      id: data.id,
      data: data
    })
      .then(data => {
        resolve(data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

export const getUserRoles = () => {
  return new Promise((resolve, reject) => {
    restClient(GET_LIST, "userRoles", {
      pagination: { page: 0, perPage: 100 },
      sort: { field: "", order: "" },
      filter: {}
    })
      .then(data => {
        resolve(data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

export const deleteUser = data => {
  return new Promise((resolve, reject) => {
    restClient("DELETE", "users", data)
      .then(data => {
        resolve(data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

export const checkUsernameExistence = data => {
  return new Promise((resolve, reject) => {
    restClient(GET_ONE, "users/checkUsernameExistence", {
      id: data
    })
      .then(data => {
        resolve(data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

export const getOrder = data => {
  return new Promise((resolve, reject) => {
    restClient(GET_ONE, "orders", {
      id: data
    })
      .then(data => {
        resolve(data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

export const getOrderItem = data => {
  return new Promise((resolve, reject) => {
    restClient(GET_ONE, "orderItems/list", {
      id: data
    })
      .then(data => {
        resolve(data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

export const loadOrderItem = data => {
  return new Promise((resolve, reject) => {
    restClient(GET_ONE, "orderItems", {
      id: data
    })
      .then(data => {
        resolve(data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

export const getOrderItemsPublic = (page, perPage, field, order, filter) => {
  return new Promise((resolve, reject) => {
    restClient(GET_LIST, "publicResources/orderItems", {
      pagination: { page: page + 1, perPage: perPage },
      sort: { field: field, order: order },
      filter: filter
    })
      .then(data => {
        resolve(data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

export const getOrderItemsForCompetition = data => {
  return new Promise((resolve, reject) => {
    restClient(GET_ONE, "publicResources/listByCompetition", {
      id: data
    })
      .then(data => {
        resolve(data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

export const checkEmailExistence = data => {
  return new Promise((resolve, reject) => {
    restClient(GET_ONE, "users/checkEmailExistence", {
      id: data
    })
      .then(data => {
        resolve(data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

export const updateClub = data => {
  return new Promise((resolve, reject) => {
    restClient("UPDATE", "clubs/updateActive", {
      id: data.id,
      data: data
    })
      .then(data => {
        resolve(data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

export const getAdFromPublicID = data => {
  return new Promise((resolve, reject) => {
    restClient(GET_ONE, "publicResources/getAdFromPublicId", {
      id: data
    })
      .then(data => {
        resolve(data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

export const getClubFromPublicID = data => {
  return new Promise((resolve, reject) => {
    restClient(GET_ONE, "publicResources/getClubFromPublicId", {
      id: data
    })
      .then(data => {
        resolve(data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

export const getCompetition = compId => {
  return new Promise((resolve, reject) => {
    restClient(GET_ONE, "competitions", {
      id: compId
    })
      .then(compData => {
        getNotificationBlocks(compId).then(
          response => {
            compData.data.notificationBlocks = response;
            resolve(compData.data);
          },
          e => {
            resolve(compData.data);
          }
        );
      })
      .catch(e => {
        reject(e);
      });
  });
};

export const getClub = clubId => {
  return new Promise((resolve, reject) => {
    restClient(GET_ONE, "clubs", {
      id: clubId
    })
      .then(data => {
        resolve(data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

export const getCompetitions = (page, perPage, field, order, filter) => {
  return new Promise((resolve, reject) => {
    restClient(GET_LIST, "competitions", {
      pagination: { page: page + 1, perPage: perPage },
      sort: { field: field, order: order },
      filter: filter
    })
      .then(data => {
        resolve(data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

export const getDeputy = (id, value) => {
  return new Promise((resolve, reject) => {
    restClient(CREATE, "profile/" + id + "/deputy", {
      data: value
    })
      .then(data => {
        resolve(data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

export const getDeputises = id => {
  return new Promise((resolve, reject) => {
    restClient(GET_ONE, "profile/" + id + "/deputises", {
      id: ""
    })
      .then(data => {
        resolve(data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

export const getCompetitionsByIds = ids => {
  return new Promise((resolve, reject) => {
    restClient(GET_MANY, "competitions", {
      ids
    })
      .then(data => {
        resolve(data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

export const getCompetitionFromPublicID = data => {
  return new Promise((resolve, reject) => {
    restClient(GET_ONE, "publicResources/getCompetitionFromPublicId", {
      id: data
    })
      .then(data => {
        resolve(data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

export const prepareOrder = data => {
  return new Promise((resolve, reject) => {
    restClient(CREATE, "publicResources/prepareOrder", { data: data })
      .then(response => {
        resolve(response.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

export const updateStand = (token, data) => {
  return new Promise((resolve, reject) => {
    restClient("UPDATE", "publicResources/updateStand/" + token, {
      id: data.id,
      data: data
    })
      .then(data => resolve(data.data))
      .catch(e => reject(e));
  });
};

export const createOrder = data => {
  return new Promise((resolve, reject) => {
    restClient(CREATE, "publicResources/createOrder", { data: data })
      .then(data => {
        resolve(data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

export const completeOrder = (token, data) => {
  return new Promise((resolve, reject) => {
    restClient("UPDATE", "publicResources/completeOrder/" + token, {
      id: data.id,
      data: data
    })
      .then(data => resolve(data.data))
      .catch(e => reject(e));
  });
};

export const getProfile = data => {
  return new Promise((resolve, reject) => {
    restClient(GET_ONE, "profile", {
      id: data
    })
      .then(data => {
        resolve(data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

export const updateProfileWithCompetitionView = type => {
  return new Promise((resolve, reject) => {
    restClient("UPDATE", "profile/competitionView", {
      id: type
    })
      .then(data => {
        resolve(data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

export const updateProfileWithShooterCompetitionView = type => {
  return new Promise((resolve, reject) => {
    restClient("UPDATE", "profile/shooterCompetitionView", {
      id: type
    })
      .then(data => {
        resolve(data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

export const getFavourite = () => {
  return new Promise(resolve => {
    restClient(GET_LIST, "competitions/favourite", {
      filter: "",
      pagination: { page: 0, perPage: 200 },
      sort: { field: "name", order: "asc" }
    })
      .then(r => resolve(r.data))
      .catch(err => {
        resolve(undefined);
      });
  });
};

export const listFavourites = (page, perPage, field, order) => {
  return new Promise(resolve => {
    restClient(GET_LIST, "competitions/favourites", {
      pagination: { page: page + 1, perPage: perPage },
      sort: { field: field, order: order }
    })
      .then(data => resolve(data))
      .catch(() => resolve(undefined));
  });
};

export const getRegistrations = () => {
  return new Promise(resolve => {
    restClient(GET_ONE, "competitions", {
      id: "registrations",
      filter: "",
      pagination: { page: 0, perPage: 200 },
      sort: { field: "name", order: "asc" }
    })
      .then(r => resolve(r.data))
      .catch(err => {
        resolve(undefined);
      });
  });
};

export const setFavourite = data => {
  return new Promise((resolve, reject) => {
    restClient(CREATE, "competitions/" + data.id + "/favourite", {
      data: data.data
    })
      .then(data => {
        resolve(data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

export const getUsers = (page, perPage, filter) => {
  return new Promise((resolve, reject) => {
    restClient(GET_LIST, "users", {
      pagination: { page: page, perPage: perPage },
      sort: { field: "lastName", order: "ASC" },
      filter: filter
    })
      .then(data => {
        resolve(data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

export const getUserBookedCompetitions = (
  page,
  perPage,
  field,
  order,
  filter
) => {
  return new Promise((resolve, reject) => {
    restClient(GET_LIST, "userBookedCompetitions", {
      pagination: { page: page + 1, perPage: perPage },
      sort: { field: field, order: order },
      filter: filter
    })
      .then(data => {
        resolve(data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

export const getAdvertisingPage = (page, perPage, field, order, filter) => {
  return new Promise((resolve, reject) => {
    restClient(GET_LIST, "publicResources/advertisingsList", {
      pagination: { page: page + 1, perPage: perPage },
      sort: { field: field, order: order },
      filter: filter
    })
      .then(data => {
        resolve(data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

export const payOrder = (id, paid) => {
  return new Promise((resolve, reject) => {
    restClient("UPDATE", "orders/pay", {
      id: id,
      data: paid
    })
      .then(data => {
        resolve(data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

export const payOrderItem = (id, paid) => {
  return new Promise((resolve, reject) => {
    restClient("UPDATE", "orderItems/pay", {
      id: id,
      data: paid
    })
      .then(data => {
        resolve(data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

export const pay2OrderItem = (id, paid) => {
  return new Promise((resolve, reject) => {
    restClient("UPDATE", "orderItems/pay2", {
      id: id,
      data: paid
    })
      .then(data => {
        resolve(data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

export const payCompetition = (id, paid) => {
  return new Promise((resolve, reject) => {
    restClient("UPDATE", "competitions/pay", {
      id: id,
      data: paid
    })
      .then(data => {
        resolve(data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

export const cancelOrder = (id, cancelled) => {
  return new Promise((resolve, reject) => {
    restClient("UPDATE", "orders/cancel", {
      id: id,
      data: cancelled
    })
      .then(data => {
        resolve(data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

export const submitOrder = (orderId, paymentMethod) => {
  return new Promise((resolve, reject) => {
    restClient("UPDATE", "publicResources/orders/submit/" + paymentMethod, {
      id: orderId
    })
      .then(data => {
        resolve(data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

export const redirectWirecardOrder = (orderId, paymentMethod) => {
  return new Promise((resolve, reject) => {
    restClient(
      GET_ONE,
      "publicResources/orders/wirecardRedirect/" + paymentMethod,
      {
        id: orderId
      }
    )
      .then(data => {
        resolve(data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

export const redirectWirecardOrderItem = orderItemId => {
  return new Promise((resolve, reject) => {
    restClient(GET_ONE, "publicResources/orderItems/wirecardRedirect", {
      id: orderItemId
    })
      .then(data => {
        resolve(data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

export const cancelOrderItem = (id, cancelled) => {
  return new Promise((resolve, reject) => {
    restClient("UPDATE", "orderItems/cancel", {
      id: id,
      data: cancelled
    })
      .then(data => {
        resolve(data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

export const cancelPublicOrderItem = (id, token) => {
  return new Promise((resolve, reject) => {
    restClient("UPDATE", "publicResources/orderItems/cancel/" + token, {
      id: id,
      data: token
    })
      .then(data => {
        resolve(data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

export const deleteOrderItem = id => {
  return new Promise((resolve, reject) => {
    restClient("DELETE", "publicResources/orderItems", { id: id })
      .then(data => {
        resolve(data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

export const competitionPaid = ids => {
  return new Promise((resolve, reject) => {
    restClient("UPDATE_MANY", "competitions/paid", {
      data: ids
    })
      .then(data => {
        resolve(data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

export const validateRegistrationCode = (reservationCode, competitionId) => {
  return new Promise((resolve, reject) => {
    restClient("UPDATE", "publicResources/validate/" + competitionId, {
      id: reservationCode
    })
      .then(data => {
        resolve(data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

export const getCompetitionResults = (page, perPage, field, order, filter) => {
  return new Promise((resolve, reject) => {
    restClient(GET_LIST, "competitionResults", {
      pagination: { page: page + 1, perPage: perPage },
      sort: { field: field, order: order },
      filter: filter
    })
      .then(data => {
        resolve(data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

export const exportCompetitionList = filter => {
  return new Promise((resolve, reject) => {
    restClient("EXPORT_LIST_COMPETITION", "exports/competitions", {
      filter: filter
    })
      .then(data => {
        resolve(data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

export const exportReservationCodeList = (
  page,
  perPage,
  field,
  order,
  filter
) => {
  return new Promise((resolve, reject) => {
    restClient("EXPORT", "exports/reservstionCodes", {
      pagination: { page: page + 1, perPage: perPage },
      sort: { field: field, order: order },
      filter: filter
    })
      .then(data => {
        resolve(data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

export const exportCompetitorList = competitionId => {
  return new Promise((resolve, reject) => {
    restClient("EXPORT", "exports/competitors", {
      filter: { competitionId: competitionId }
    })
      .then(data => {
        resolve(data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

export const exportAdminCompetitionList = (clubs, filter) => {
  return new Promise((resolve, reject) => {
    restClient("EXPORT_ADMIN_COMPETITIONS", "exports/adminCompetitions", {
      data: clubs,
      filter: filter
    })
      .then(data => {
        resolve(data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

export const exportArchivedCompetitions = () => {
  return new Promise((resolve, reject) => {
    restClient("EXPORT", "exports/userBookedArchivedCompetitions", {
      sort: { field: "competition.competitionDate", order: "desc" },
      filter: {}
    })
      .then(data => {
        resolve(data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

export const exportallCompetitors = () => {
  return new Promise((resolve, reject) => {
    restClient("EXPORT", "exports/allCompetitors", {})
      .then(data => {
        resolve(data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

export const deactivateUnpaidUsers = () => {
  return new Promise((resolve, reject) => {
    restClient("CREATE", "users/deactivateUnpaid", {})
      .then(data => {
        resolve(data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

export const exportallUsers = () => {
  return new Promise((resolve, reject) => {
    restClient("EXPORT", "exports/allUsers", {})
      .then(data => {
        resolve(data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

export const exportAllAdvertisment = (from, to) => {
  return new Promise((resolve, reject) => {
    restClient("EXPORT", "exports/advertisements", {
      filter: { from: from, to: to }
    })
      .then(data => {
        resolve(data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

export const exportPractiScoreForCompetition = competitionId => {
  return new Promise((resolve, reject) => {
    restClient("EXPORT", "exports/practiScore", {
      filter: { competitionId: competitionId }
    })
      .then(data => {
        resolve(data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

export const exportPractiScoreWithStandForCompetition = competitionId => {
  return new Promise((resolve, reject) => {
    restClient("EXPORT", "exports/practiScoreStand", {
      filter: { competitionId: competitionId }
    })
      .then(data => {
        resolve(data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

export const checkPreRegistration = (id, orderId, squadId) => {
  return new Promise((resolve, reject) => {
    let url = "publicResources/subscriptionInfo/" + id + "/" + orderId;

    if (squadId) {
      url += "/" + squadId;
    }

    restClient(GET_ONE, url)
      .then(data => {
        resolve(data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

export const changeSquad = (sourceId, targetId) => {
  return new Promise((resolve, reject) => {
    rest(CREATE, "orderItems/changeSquad/" + sourceId, {
      data: targetId
    })
      .then(data => {
        resolve(data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

export const checkRegistrationWindow = id => {
  return new Promise((resolve, reject) => {
    restClient(GET_ONE, "publicResources/competitionInRegistrationWindow/" + id)
      .then(data => {
        resolve(data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

export const checkIfSpotStillFree = squadId => {
  return new Promise((resolve, reject) => {
    restClient(GET_ONE, "publicResources/checkIfSpotStillFree/" + squadId)
      .then(data => {
        resolve(data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

export const exportFilteredOrderItems = (filter, orderBy, order) => {
  return new Promise((resolve, reject) => {
    restClient("EXPORT", "exports/orderItems", {
      sort: { field: orderBy, order: order },
      filter: { ...filter }
    })
      .then(data => {
        resolve(data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

export const clickAd = id => {
  return new Promise((resolve, reject) => {
    restClient(GET_ONE, "publicResources/clickAd", { id: id })
      .then(data => resolve(data))
      .catch(e => reject(e));
  });
};

export const getPublicOrderItemForEdit = (id, token) => {
  return new Promise((resolve, reject) => {
    restClient(GET_ONE, "publicResources/orderItems/" + id + "/" + token)
      .then(data => resolve(data))
      .catch(e => reject(e));
  });
};

export const updatePublicOrderItem = (id, token, data) => {
  return new Promise((resolve, reject) => {
    restClient("UPDATE", "publicResources/orderItems/" + token, {
      id: data.id,
      data: data
    })
      .then(data => resolve(data))
      .catch(e => reject(e));
  });
};

export const updateOrderItem = (id, data) => {
  return new Promise((resolve, reject) => {
    restClient("UPDATE", "orderItems", {
      id: data.id,
      data: data
    })
      .then(data => resolve(data))
      .catch(e => reject(e));
  });
};

export const updateShooterOrderItem = data => {
  return new Promise((resolve, reject) => {
    restClient("UPDATE", "shooterOrderItems", {
      id: data.id,
      data: data
    })
      .then(data => {
        resolve(data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

export const getShooterOrderItem = id => {
  return new Promise((resolve, reject) => {
    restClient(GET_ONE, "shooterOrderItems/" + id)
      .then(data => resolve(data))
      .catch(e => reject(e));
  });
};

export const checkAuth = () => {
  return new Promise((resolve, reject) => {
    restClient(GET_ONE, "users/checkAuth")
      .then(data => {
        resolve(data);
      })
      .catch(e => {
        reject(e);
      });
  });
};
