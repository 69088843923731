import germanMessages from "ra-language-german";

germanMessages.ra.notification.updated =
  "Die Änderungen wurden erfolgreich gespeichert.";
export default {
  ...germanMessages,
  BARZAHLUNG: "Barzahlung direkt beim Bewerb",
  VORAUSZAHLUNG: "Überweisung auf das Konto des Vereins",
  WIRECARD: "Zahlung mittels externen Zahlungsprovider",
  pos: {
    search: "Suche",
    profile: "Profil",
    signout: "Abmelden",
    configuration: "Einstellungen",
    language: "Sprache",
    theme: {
      name: "Theme",
      light: "Light",
      dark: "Dark"
    },
    headerImage: {
      headline: "Willkommen bei comp | sign",
      headlineMobile: "Willkommen bei",
      compSign: "comp | sign",
      btnCompetitions: "Zu den Bewerben",
      btnClubs: "Zu den Vereinen",
      btnResults: "Zu den Ergebnislisten"
    }
  },
  dashboard: {
    headline: "Startseite",
    myUpcomingEvents: {
      title: "Meine nächsten Termine",
      moreButton: "Zu allen meinen Terminen"
    },
    myParticipatedEvents: {
      title: "Meine vergangenen Teilnahmen",
      moreButton: "Zu allen meinen Teilnahmen"
    },
    guestContent: {
      compSignFunctions: "comp | sign Funktionsumfang",
      registrationAdvantages: "Vorteile durch die Registrierung",
      registration:
        "Um in den Genuss des Premuimdienst zu kommen registriere dich, oder melde dich als Gast an.",
      functions: "den kompletten Funktionsumfang von comp | sign.",
      look: "Siehe Hier",
      introduction:
        "Du findest hier alle Bewerbe der Partnervereine inkl. der  Ergebnislisten als auch die Möglichkeit dich zu Trainings oder Sicherheitszulassungen anzumelden. ",
      introduction2:
        "Für Schützen ist die Anmeldung zu Bewerben über comp|sign grundsätzlich kostenlos!",
      introduction3:
        "Wenn Du in den Genuss der Premiumfunktionen kommen willst, dann registriere dich bitte.",
      introduction4:
        '(Die Möglichkeit dazu findest Du rechts oben beim Button "Einloggen")'
    },
    welcome: {
      headline: "Willkommen bei comp | sign",
      headlineGuest:
        "Das Österreichische Anmeldeportal für Sportschützen heißt dich Willkommen!",
      headlineUser: "Willkommen zurück, %{fullName}",
      textGuest:
        "Das österreichische Anmeldeportal für Sportschützen. Melden Sie sich jetzt an und profitieren Sie von vielen Vorteilen.",
      textShooter:
        "finde darunter die Bewerbe bei denen Du angemeldet bist und auch einen Rückblick deiner absolvierten Bewerbe.",
      greetingsShooter:
        "Das Team von comp | sign wünscht Dir weiterhin viel Erfolg",
      textPortalAdmin:
        "Das österreichische Anmeldeportal für Sportschützen. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.",
      textSystemAdmin:
        "Das österreichische Anmeldeportal für Sportschützen. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.",
      textClubAdmin:
        "Das österreichische Anmeldeportal für Sportschützen. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.",
      whyRegister: "Vorteile durch die Registrierung"
    },
    teaser: {
      btnMore: "MEHR",
      results: {
        headline: "Ergebnislisten",
        imgTitle: "Siegerteam mit Pokal in den Händen"
      },
      competitions: {
        headline: "Bewerbe",
        imgTitle: "Rauchende Pistole"
      },
      clubs: {
        headline: "Vereine",
        imgTitle: "Menschengruppe hält sich an den Händen"
      }
    },
    competitions: {
      headline: "Bewerbe",
      btnAllCompetitions: "ALLE BEWERBE",
      btnCompetition: "ZUM BEWERB",
      btnEvent: "ZUR VERANSTALTUNG"
    }
  },
  customAutoComplete: {
    placeholder: "Auswählen..."
  },
  orderList: {
    id: "Id",
    firstName: "Vorname",
    lastName: "Nachname",
    email: "E-Mail",
    payButton: "Bezahlt",
    cancelButton: "Stornieren",
    openDetailsButton: "Details"
  },
  appBar: {
    login: "Einloggen",
    profil: "Profil",
    loginForm: {
      loginHeader: "Einloggen",
      forgotPassword: "Passwort vergessen?",
      cancel: "Abbrechen",
      loginDescription:
        "Sie können Sich nun hier anmelden oder neu registrieren.",
      noAccountYet: "Noch kein Account? Hier registrieren.",
      username: "Benutzername",
      password: "Passwort",
      doLogin: "Jetzt einloggen"
    },
    noPaymentUntil: "Mitgliedsbeitrag noch nicht eingezahlt.",
    notSupportedBrowser: "Browser wird nicht unterstützt"
  },
  menu: {
    mainTitle: "comp | sign Menu",
    exports: "Exports und Auswertungen",
    managementTitle: "Verwaltung",
    clubDataTitle: "Mein Verein",
    dashboard: "Start",
    reservations: "Reservierungen",
    upcomingCompetitions: "Zukünftige Bewerbe",
    competitionArchive: "Ergebnislisten",
    clubs: "Vereinsübersicht",
    register: "Als Schütze registrieren",
    registerTitle: "Jetzt anmelden",
    myData: "Meine Daten",
    myCompetitions: "Meine Bewerbe",
    profile: "Mein Profil",
    clubProfile: "Vereinsprofil",
    clubCompetitions: "Vereinsbewerbe",
    clubEvents: "Vereinsveranstaltungen",
    clubReservationCodes: "Reservierungscodes",
    clubOrders: "Rechnungen",
    clubItemOrders: "Anmeldungen",
    settings: {
      uploadTypes: "Downloads",
      competitions: "Bewerbe",
      clubs: "Vereine",
      reservationCodes: "Reservierungscodes",
      orders: "Rechnungen",
      orderItems: "Anmeldungen",
      users: "Benutzerverwaltung",
      userRoles: "Benutzerrollen",
      factors: "Faktoren",
      competitionClasses: "Klassen",
      divisions: "Divisionen",
      levels: "Levels",
      competitionTypes: "Bewerbstypen",
      events: "Veranstaltungen"
    },
    pages: {
      whyRegister: "Meine Vorteile als registrierter Schütze",
      information: "Informationswebseite",
      about: "Über comp | sign",
      changelog: "About und Changelog",
      costs: "Was comp | sign kostet",
      advertisings: "Werbung"
    }
  },
  footer: {
    info:
      "These are the voyages of the Starship Enterprise. Its continuing mission, to explore strange new worlds, to seek out new life and new civilizations, to boldly go where no one has gone before.",
    copyright: "© 2019 comp|sign Thomas Tuschl",
    address: "Zwerbach 35a, 3244 Ruprechtshofen",
    email: "support@compsign.net",
    phone: "+43 664 3968212",
    links: "Links",
    socialMedia: "Social Media",
    changelog: "About und Changelog",
    conditions: "AGBs",
    dataProtection: "Datenschutz",
    impressum: "Impressum"
  },
  copyButton: {
    tooltip: "Kopie erstellen"
  },
  backButton: {
    tooltip: "Zurück"
  },
  premiumdienste: {
    title: "Premiumdienste für registrierte Schützen",
    sortingAndFilter: "Bewerbs- Teilnehmerliste Filter- und Sortierbar",
    registration:
      "Durch die Registrierung ist der Anmeldeprozess zu den Bewerben viel schneller, da Division, Klasse und Faktor nicht immer wieder angegeben werden müssen",
    edit:
      "Selbständige Veränderung aller für den Bewerb notwendigen Daten bis die Anmeldung geschlossen wird, inkl. das Verschieben in andere Squads",
    overview: "Übersicht über alle angemeldeten Bewerbe",
    export:
      "Exportliste aller teilgenommen Bewerbe. (Praktisch als Nachweis für WBK Erweiterungen)",
    waitingList:
      "Wartelistenanmeldung in mehrere gewünschte Squads gleichzeitig",
    filter: "Filterung der Bewerbe des Schützen (Pistole, Rifle,…)",
    print: "Export der Teilnehmerlisten eines Bewerbs zum Ausdrucken",
    notifications: "Erinnerungsfunktionen | Info an Schützen",
    favourite:
      "Erinnerung kurz vor Anmeldestart von als Favorit markierten Bewerben",
    subscriptions: "Erinnerungen für angemeldete Bewerbe",
    waitingListNotification:
      "Information wenn ein Startplatz, zu dem man auf der Warteliste war nicht fixiert werden konnte",
    freePlaces:
      "Information wenn Startplatz in einer Squad frei wird, den Schütze eigentlich nicht als Option für den Start markiert hat",
    newCompNotification:
      "Information wenn ein Bewerb online gestellt wurde, welche der Standard Waffenkategorie des Schützen entspricht",
    price: "Preis pro Jahr: 20,00 €",
    download: "Selbständiger Download von Nenngeldbestätigungen",
    abo: "Abo Funktion für Mitgliedsbeitrags-Verwaltung"
  },
  orderCompletePage: {
    orderSucessMessage: "Ihre Anmeldung wurde erfolgreich durchgeführt.",
    orderMessageWirecard:
      "Die Bezahlung mittels externen Zahlungsprovider wurde erfolgreich durchgeführt. Ihr Startplatz ist somit fixiert.",
    orderSucessTitle: "Anmeldung",
    myCompetitions: "Zu meinen Bewerben",
    competitions: "Zu den Bewerben"
  },
  userRegisterSuccessPage: {
    welcome: "Willkommen bei Comp|Sign",
    userRegisterSuccessMessage:
      "Ihre Registrierung wurde erfolgreich durchgeführt. Sie erhalten in kürze ein E-Mail zur Verifizierung ihrer E-Mail Adresse von uns.",
    competitions: "Zu den Bewerben"
  },
  orderFailPage: {
    orderFailMessage:
      "Leider trat während des Zahlungsprozesses ein Fehler auf. Ihre Anmeldung wurde nicht durchgeführt und Ihr Konto nicht belastet. Bitte versuchen Sie den Zahlungsvorgang erneut durchzuführen oder wählen Sie eine andere Bezahlungsmöglichkeit.",
    orderFailTitle: "Anmeldung fehlgeschlagen"
  },
  checkout: {
    headline: "Warenkorb & Checkout",
    next: "weiter",
    back: "zurück",
    finished: "fertig",
    summary: "Details der Anmeldung(en)",
    competitions: "Bewerbe",
    table: {
      competition: "Bewerb",
      shooter: "Schütze",
      date: "Datum",
      price: "Preis",
      currency: "€",
      total: "Gesamtpreis",
      division: "Division",
      competitionClass: "Bewerbsklasse"
    },
    choosePaymentMethod: "Bitte wählen Sie Ihre Zahlungsart:",
    paymentMethod: "Zahlungsmethode",
    goToCompetitions: "zu den Bewerben",
    agbs:
      "Bestätigen Sie bitte die AGBs und klicken Sie auf Fertig um Ihre Anmeldung zu bestätigen!",
    waitingListPaymentInfo:
      "*Bitte für Wartelistenplätze keine Teilnahmegebühr bezahlen!",
    select: "Zahlungsart auswählen...",
    paymentError: "Pflichtfeld",
    toWirecard: "Weiter zum Zahlungsprovider"
  },
  resources: {
    orderShow: {
      competitionName: "Bewerb",
      competitionDate: "Bewerbsdatum",
      firstName: "Vorname",
      lastName: "Nachnahme",
      email: "E-Mail",
      squadName: "Squad",
      squadStandName: "Stand",
      orderId: "Bestellnummer",
      orderItems: "Bestellung",
      orderDetails: "Bestelldetails:",
      className: "Klasse",
      factorName: "Faktor",
      divisionName: "Division",
      m: {
        competitionName: "Bewerb",
        competitionDate: "Bewerbsdatum",
        firstName: "Vorname",
        lastName: "Nachnahme",
        email: "E-Mail",
        squadName: "Squad",
        squadStandName: "Stand",
        orderDetails: "Bestelldetails:",
        className: "Klasse",
        factorName: "Faktor",
        divisionName: "Division"
      }
    },
    advertising: {
      listPage: {
        headline: "Werbung"
      },
      createPage: {
        headline: "Werbung"
      },
      editPage: {
        headline: "Werbung"
      },
      fromDate: "von",
      toDate: "bis",
      buttonText: "Button Text",
      buttonLink: "Link",
      imageRaw: "Bild",
      detailImageRaw: "DetailBild",
      name: "Title",
      shortName: "Kurztitle",
      text: "Beschreibung"
    },
    signupFinish: {
      header: "Deine Anmeldung wurde gespeichert!",
      content1:
        "Du erhältst in Kürze ein Bestätigungsmail für die Anmeldung in dem sich auch ein Link zur Bearbeitung der Anmeldung befindet.",
      content2:
        "Wenn vom Verein zugelassen und erwünscht, melde Dich erneut in einer anderen Division an, oder beende den Anmeldeprozess",
      newSignup: "Erneut anmelden",
      gotoCart: "zum Warenkorb",
      goBacktoCompetition: "Schliessen"
    },
    exports: {
      title: "Exports",
      types: {
        title: "Export auswählen",
        competitorsGroupedByCompetition:
          "Liste von allen Anmeldungen aller Bewerbe",
        allUsers: "Liste aller Schützen",
        advertisments: "Werbungen",
        competitionPractiScore: "Bewerbs-PractiScore",
        competitionPractiScoreStand: "Bewerbs-PractiScore mit Ständen",
        competitions: "Bewerbe"
      },
      clubs: "Berücksichtigte Vereine",
      exportSquads: "Squad-Daten exportieren",
      exportOrderItems: "Squad-Anmeldungen exportieren",
      division: "Division",
      competitionClass: "Klasse",
      factor: "Faktor",
      submit: "Download",
      competition: "Bewerb"
    },
    orderEdit: {
      club: "Verein",
      country: "Land",
      headline: "Anmeldungen bearbeiten",
      publicOrderItemEdit: "Anmeldung bearbeiten",
      orderItemNumber: "Anmeldenummer",
      firstName: "Vorname",
      lastName: "Nachname",
      email: "Email",
      competition: "Bewerb",
      divisions: "Divisionen",
      division: "Division",
      competitionClass: "Bewerbsklasse",
      factor: "Faktor",
      squad: "Squad",
      squadStand: "Stand",
      errorMessage:
        "Fehler beim Laden der Anmeldung. Bearbeitungszeitraum bereits verstrichen."
    },
    manage: {
      name: "Verwaltung"
    },
    competitionList: {
      name: "Bewerbe",
      competitionName: "Bewerbe",
      code: "Code",
      used: "Verbraucht",
      createdAt: "Erstelldatum"
    },
    orderItemList: {
      title: "Anmeldungen",
      cancel: "Anmeldung aktiv, abmelden?",
      uncancel: "Abgemeldet, Abmeldung aufheben?",
      ORDER_SUBMITTED: "Anmeldung angelegt"
    },
    uploadList: {
      title: "Downloads"
    },
    orderList: {
      country: "Land",
      name: "Rechnungen",
      titleClubAdmin: "Alle Rechnungen zu Vereinsbewerben",
      titleAdmin: "Alle Rechnungen",
      id: "#",
      firstName: "Vorname",
      lastName: "Nachname",
      email: "Email",
      payButton: "Bezahlstatus",
      edit: "Bearbeiten",
      selectedClubName: "Verein",
      cancelButton: "",
      openDetailsButton: " ",
      competitionName: "Bewerb",
      competitionCompetitionDate: "Bewerbsdatum",
      fromDate: "Angemeldet von",
      toDate: "Angemeldet bis",
      clubName: "Verein",
      paid: "Bezahlstatus",
      paid2: "Bezahlt(intern)",
      change: "Wechsel",
      changeHeader: "Platz tauschen",
      changeOrderItemSquad: "Squad tauschen",
      setToPaid: "Bezahlt: Als offen markieren?",
      setToNotpaid: "Offen: Als bezahlt markieren?",
      paymentMethod: "Zahlungsart",
      squadName: "Squad",
      squadStandName: "Stand",
      timeZonedOrderCreateDate: "Angemeldet am",
      divisionName: "Division",
      competitionClassName: "Klasse",
      timeZonedCancelledDate: "Abgemeldet am",
      isRegistered: "Anmeldestatus",
      waitingList: "S/W",
      userId: "User",
      payOrderPopup: {
        title: "Bestätigen",
        confirm: "Bestätigen",
        cancel: "Abbrechen",
        warning:
          "Wollen Sie wirklich alle Anmeldungen in der Bestellung auf bezahlt setzten?"
      },
      cancelOrderPopup: {
        title: "Bestätigen",
        confirm: "Bestätigen",
        cancel: "Abbrechen",
        warning:
          "Wollen Sie wirklich alle Anmeldungen in der Bestellung absagen?"
      },
      cancelOrderItemPopup: {
        title: "Absagen",
        confirm: "Bestätigen",
        cancel: "Abbrechen",
        warning: "Wollen Sie den Schützen wirklich abmelden?"
      },
      shooterCancelOrderItemPopup: {
        title: "Abmelden",
        confirm: "Bestätigen",
        cancel: "Abbrechen",
        warning: "Sind Sie sicher, dass Sie sich abmelden wollen?"
      },
      unCancelOrderItemPopup: {
        title: "Stornierung rückgängig machen?",
        warning: "Wollen Sie das Storno der Anmeldung rückgängig machen?"
      },
      payOrderItemPopup: {
        title: "Bezahlen",
        confirm: "Ja",
        cancel: "Nein",
        warning: "Sind Sie sicher?",
        warning2:
          "Interne Bezahlt Markierung setzen\n(Achtung, diese Funktion steuert NICHT die tatsächliche Bezahlung des Schützen)"
      },
      unPayOrderItemPopup: {
        title: "Bezahlung rückgängig machen?"
      }
    },
    dashboard: {
      name: "Start"
    },
    userRegister: {
      name: "Neuer Schütze"
    },
    reservationCodes: {
      name: "Reservierungs Codes erstellen",
      titleAdmin: "Alle Reservierungs Codes",
      titleClubAdmin: "Alle Reservierungscodes für Vereinsbewerbe",
      submit: "Codes erstellen",
      amount: "Menge",
      competition: "Bewerb",
      generating: "Die Codes werden generiert!",
      reservationSuccess: "Die Codes wurden erfolgreich generiert.",
      create: "Neue Reservierungscodes erstellen"
    },
    reservationCodesList: {
      name: "Reservierungs Codes",
      headline: "Reservierungs Codes Liste",
      submit: "Codes erstellen",
      amount: "Menge",
      competition: "Bewerb",
      generate: "Neuen Code generieren"
    },
    profile: {
      editProfile: "Profil bearbeiten",
      tabs: {
        notificationSelection: "Benachrichtigungen",
        userInformation: "Profildaten",
        userContactData: "Kontaktdaten",
        deputies: "Stellvertreter",
        shooterData: "Schützendaten",
        reservations: "Meine Reservierungen"
      },
      reservations: {
        maxReservations: "Anzahl Reservierungen",
        qualification: "Qualifikationsbewerb",
        validUntil: "Gültig bis",
        compClasses: "Bewerbsklassen",
        factors: "Faktoren",
        divisions: "Divisionen",
        competitions: "Bewerbe",
        reservation: "Reservierung"
      },
      fields: {
        firstName: "Vorname",
        lastName: "Nachname",
        emial: "Email",
        notificationSelection: {
          newShooterRegistrations: "Neu-Registrierung eines Schützen",
          newRegistrationsToCompetitions: "Anmeldung zu einem meiner Bewerbe",
          registrationChangeRegistrationSuccess:
            "Anmeldung zu einem Bewerb erfolgreich",
          registrationChangePaymentReceived:
            "Zahlung für einen Bewerb erhalten",
          registrationChangeRegistrationCancelledByAdministrator:
            "Anmeldung durch Vereins-Admin zurückgezogen",
          registrationChangeRegistrationCancelledByShooter:
            "Anmeldung durch den Benutzer zurückgezogen",
          registrationChangeRegistrationCancelledNoPayment:
            "Anmeldung aufgrund Nicht-Einzahlung des Startgelds verfallen",
          competitionStartReminder: "Erinnerung: Bewerb beginnt in Kürze",
          competitionPaymentReminder: "Erinnerung: Ausstehende Zahlung",
          memberCancelNoPaymentReminder:
            "Bevorstehende Abmeldung wegen fehlender Zahlung",
          memberCancelNoPayment: "Abmeldung wegen fehlender Zahlung",
          gettingStartingPlace: "Startplatz erhalten",
          newCompetitionCreated:
            "Neuer Bewerb meiner Waffenklasse wurde angelegt",
          signupPhaseOfFavourizedCompetitionStarted:
            "Erinnerung: Anmeldephase meines favorisierten Bewerbs startet",
          signupPhaseEndsReminder: "Anmeldephase beendet",
          squadPlaceFree: "In einer Squad wurde ein Platz frei"
        }
      }
    },
    client: {
      name: "Mandant"
    },
    clients: {
      name: "Mandanten",
      fields: {
        name: "Name",
        active: "Aktiv",
        id: "ID"
      }
    },
    unpaidUsers: {
      blockDialog: {
        header: "Alle nicht bezahlten Benutzer sperren?",
        reallyBlock:
          "Möchten Sie alle nicht bezahlten Benutzer wirklich sperren?",
        cancel: "Abbrechen",
        doBlock: "Jetzt sperren"
      },
      blockButton: "Unbezahlt sperren?"
    },
    users: {
      listPage: {
        headline: "Alle Benutzer"
      },
      createPage: {
        headline: "Benutzer erstellen"
      },
      editPage: {
        headline: "Benutzer bearbeiten"
      },
      showPage: {
        headline: "Benutzerinformation"
      },
      confirmPasswortReset: {
        headline: "Passwort zurücksetzen",
        resetSuccess:
          "Ihr Passwort wurde erfolgreich zurückgesetzt. Sie erhalten in Kürze ein Email mit Ihrem neuen Passwort.",
        resetError: "Ein Fehler ist aufgetreten.",
        goToLogin: "Zum Login"
      },
      resetPasswordHeadline: "Passwort zurücksetzen",
      resetPasswordFormFinished:
        "Sie erhalten in Kürze ein Email mit allen weiteren Informationen.",
      resetPasswordForm: {
        email: "Email",
        reset: "Passwort zurücksetzen"
      },
      name: "Benutzer",
      deleteButton: "Element löschen",
      deleteDialog: {
        header: "Diesen Benutzer löschen?",
        reallyDelete:
          "Wollen Sie diesen Benutzer wirklich löschen? Diese Aktion kann nicht rückgängig gemacht werden!",
        cancel: "Abbrechen",
        doDelete: "Jetzt löschen"
      },
      blockButton: {
        block: "Aktiv: Sperren?",
        unblock: "Gesperrt: Entsperren?"
      },
      verifyButton: {
        verify: "Unbestätigt: Benutzer bestätigen?",
        unVerify: "Bestätigt: Bestätigung aufheben?"
      },
      blockDialog: {
        header: "Diesen Benutzer sperren?",
        headerUnblock: "Diesen Benutzer entsperren?",
        reallyBlock: "Möchten Sie diesen Benutzer wirklich sperren?",
        reallyUnblock: "Möchten Sie diesen Benutzer wirklich entsperren?",
        cancel: "Abbrechen",
        doBlock: "Jetzt sperren",
        doUnblock: "Jetzt entsperren"
      },
      verifyDialog: {
        header: "Diesen Benutzer bestätigen?",
        headerUnverify: "Bestätigung für diesen Benutzer aufheben?",
        really: "Möchten Sie diesen Benutzer wirklich bestätigen?",
        reallyUnverify:
          "Möchten Sie die Bestätigung für diesen Nutzer wirklich aufheben?",
        cancel: "Abbrechen",
        doVerify: "Jetzt bestätigen",
        doUnverify: "Jetzt aufheben"
      },
      fields: {
        isActive: "Benutzer ist aktiv",
        isBlocked: "Benutzer ist gesperrt",
        isEnabled: "Benutzer ist aktiviert",
        isDisabled: "Benutzer ist deaktiviert",
        club: {
          id: "Verein",
          name: "Verein"
        },
        email: "Email",
        userRole: "Benutzerrolle",
        userRoleIds: "Benutzerrolle",
        permissions: "Berechtigung",
        password: "Passwort",
        password2: "Passwort wiederholen",
        userRolePk: "Benutzerrolle",
        userRoles: "Benutzerrolle",
        clientPk: "Mandant",
        firstName: "Vorname",
        lastName: "Nachname",
        username: "Benutzername",
        createdAt: "Mitglied seit",
        active: "Aktiv",
        client: {
          name: "Mandant",
          id: "Mandant"
        },
        notPaid: "Nur unbez.",
        paymentUntil: "Bezahlstatus - Bezahlt bis"
      },
      registerForm: {
        header: "Als Schütze registrieren",
        register: "Registrierung abschließen",
        back: "zurück",
        next: "weiter",
        addDeputy: "Stellvertreter hinzufügen",
        formTabs: {
          userInformation: "Profildaten",
          userContactData: "Kontaktdaten",
          shooterData: "Schützendaten"
        },
        fields: {
          username: "Benutzername",
          salutation: "Anrede",
          salutations: {
            mr: "Herr",
            mrs: "Frau"
          },
          selectedPaymentMethod: "Zahlungsmethode",
          degree: "Titel",
          firstName: "Vorname",
          lastName: "Nachname",
          email: "Email",
          password: "Passwort",
          passwordValidate: "Passwort Wiederholen",
          selectedLanguage: "Sprache",
          languages: {
            de: "Deutsch",
            en: "Englisch"
          },
          countries: {
            germany: "Deutschland",
            austria: "Österreich"
          },
          address: "Adresse",
          zip: "PLZ",
          city: "Ort",
          state: "Bundesland",
          country: "Land",
          telephone: "Telefonnummer",
          competitionClass: "Klasse",
          competitionDivision: "Division",
          competitionDivisionNotification:
            "Informiere mich über neue Matches in folgenden Divisionen",
          competitionFactor: "Faktor",
          club: "Verein",
          alias: "Vereins Alias",
          cowboyName: "Cowboy-Name",
          sassNumber: "SASS Nummer",
          ipscNumber: "IPSC Nummer",
          ipscRegion: "IPSC Region",
          ipscAlias: "IPSC Alias",
          allowInformations:
            "Zustimmung zu Erinnerungs- und Informations-E-Mails",
          conditions: "Ich habe die AGB gelesen und akzeptiert",
          payment: "Ich möchte mich kostenpflichtig registrieren",
          competitionViewType: "Bewerbe in Listenansicht darstellen",
          shooterCompetitionViewType:
            "Meine Bewerbe in Listenansicht darstellen",
          deputy: "Stellvertreter (Mailadresse)"
        }
      }
    },
    userRoles: {
      listPage: { headline: "Alle Benutzerrollen" },
      creatPage: { headline: "Benutzerrole erstellen" },
      editPage: { headline: "Benutzerrole bearbeiten" },
      showPage: { headline: "Benutzerrolinformation" },
      tabs: {
        userRoleData: "Benutzerrolle",
        userRolePermissions: "Berechtigungen",
        permissions: "Berechtigungen"
      },
      fields: {
        ROLE_USER: "Schütze",
        permissions: "Berechtigungen",
        clientPk: "Mandant",
        name: "Benutzerrollen",
        ROLE_ADMIN: "System Administrator",
        client: {
          name: "Mandant",
          id: "Mandant"
        }
      },
      name: "Benutzerrollen",
      list: "Benutzerrollen"
    },
    factors: {
      name: "Faktoren",
      listPage: { headline: "Alle Faktoren" },
      createPage: { headline: "Faktor erstellen" },
      editPage: { headline: "Faktor bearbeiten" }
    },
    competitionClasses: {
      name: "Klassen",
      listPage: {
        headline: "Alle Klassen"
      },
      createPage: {
        headline: "Klasse erstellen"
      },
      editPage: {
        headline: "Klasse bearbeiten"
      }
    },
    divisions: {
      name: "Divisionen",
      listPage: {
        headline: "Alle Divisionen"
      },
      createPage: {
        headline: "Division erstellen"
      },
      editPage: {
        headline: "Division bearbeiten"
      },
      fields: {
        competitionType_ids: "Bewerbstypen"
      }
    },
    levels: {
      name: "Levels",
      listPage: {
        headline: "Alle Levels"
      },
      createPage: {
        headline: "Level erstellen"
      },
      editPage: {
        headline: "Level bearbeiten"
      }
    },
    competitionTypes: {
      name: "Bewerbstypen",
      listPage: {
        headline: "Alle Bewerbstypen"
      },
      createPage: {
        headline: "Bewerbstype erstellen"
      },
      editPage: {
        headline: "Bewerbstype bearbeiten"
      }
    },
    topics: {
      name: "Themengebiete"
    },
    uploadTypes: {
      fields: {
        sortingOrder: "Sortierungspriorität",
        showName: "Dateinamen anzeigen"
      }
    },
    clubProfile: {
      fields: {
        name: "Name",
        longName: "Vereinsname (lang)",
        address: "Adresse",
        city: "Stadt",
        zip: "Postleitzahl",
        country: "Land",
        googleMapsLink: "Google Maps Link",
        publicLink: "Öffentlicher Link",
        websiteLink: "Vereinswebsite",
        matchAddress: "Matchadresse Straße",
        matchCity: "Matchadresse Ort",
        matchCountry: "Matchadresse Land",
        matchZip: "Matchadresse PLZ",
        logo: "Vereinslogo",
        contact: {
          firstName: "Vorname",
          lastName: "Nachname",
          email: "Email",
          telephone: "Telefon",
          fullName: "Ansprechpartner"
        }
      }
    },
    clubs: {
      name: "Vereine",
      tabs: {
        notificationBlocks: "Informations-Blöcke",
        club: "Vereinsinformation",
        invoice: "Verrechnung"
      },
      publicShow: {
        NO_DATA_AVAILABLE: "Wir suchen die gewünschten Daten.",
        competitions: "Vereinsbewerbe",
        name: "Name",
        stages: "Stages",
        competitionDate: "Datum",
        publicLink: "Link",
        favourite: "Favourite",
        competitionTypeName: "Bewerbstype",
        toCompetitionButton: "",
        toTheCompetition: "Zum Bewerb",
        toTheEvent: "Zur Veranstaltung",
        events: "Vereinsveranstaltungen",
        eventType: "Typ der Veranstaltung",
        archive: "Archiv"
      },
      listPage: {
        headline: "Alle Vereine",
        btnClub: "ZUM VEREIN",
        filters: {
          paymentUntilFrom: "Bezahlt Von",
          paymentUntilTo: "Bezahlt Bis"
        }
      },
      createPage: {
        headline: "Verein erstellen"
      },
      editPage: {
        headline: "Verein bearbeiten"
      },
      showPage: {
        websiteLink: "Link zur Vereins-Website",
        googleMapsLink: "Google Maps",
        contactTelefon: "Ansprechpartner telefon",
        contactInfosHeadline: "Kontaktinformationen",
        address: "Vereinsadresse:",
        matchaddress: "Matchadresse:",
        contact: "Kontakt:",
        contactEmail: "Email:",
        bankAccountInfosHeadline: "Bankdaten",
        bank: {
          information: "Bank Informationen",
          name: "Name",
          bic: "BIC",
          iban: "IBAN"
        },
        goToWebsite: "ZUR VEREINS WEBSITE"
      },
      blockDialog: {
        header: "Diesen Verein sperren?",
        headerUnblock: "Diesen Verein entsperren?",
        reallyBlock: "Möchten Sie diesen Verein wirklich sperren?",
        reallyUnblock: "Möchten Sie diesen Verein wirklich entsperren?",
        cancel: "Abbrechen",
        doBlock: "Jetzt sperren",
        doUnblock: "Jetzt entsperren"
      },
      fields: {
        noPayment: "Bisher keine Zahlung eingegangen",
        unlisted: "Ungelisteter Verein",
        paymentExpired: "Zahlung abgelaufen",
        validPayment: "Zahlung ist eingegangen",
        isBlocked: "Verein ist gesperrt.",
        isActive: "Verein ist aktiv",
        clubAdmins: "Vereins-Admins",
        contactHeader: "Ansprechpartner",
        clubHeader: "Vereinsinformation",
        matchHeader: "Bewerbinformation",
        paymentHeader: "Bezahlstatus",
        paymentUntil: "Bezahlstatus - Bezahlt bis",
        name: "Vereinsname",
        longName: "Vereinsname (Lang)",
        address: "Adresse",
        active: "Aktiv",
        city: "Ort",
        zip: "PLZ",
        country: "Land",
        matchAddress: "Matchadresse Straße",
        matchCity: "Matchadresse Ort",
        matchCountry: "Matchadresse Land",
        matchZip: "Matchadresse PLZ",
        googleMapsLink: "Google Maps Link",
        logo: "Vereinslogo",
        websiteLink: "Vereinswebsite",
        contact: {
          firstName: "Vorname",
          lastName: "Nachname",
          email: "Email",
          telephone: "Telefon",
          fullName: "Ansprechpartner"
        },
        bmdAccountNumber: "BMD Account Nummer",
        invoiceMail: "Rechnungsempfänger",
        invoiceName: "Ansprechpartner Rechnung"
      }
    },
    clubList: {
      name: "Vereine"
    },
    name: "Bewerb",
    maintenanceWindows: {
      name: "Maintenance Windows",
      cancel: "Cancel",
      clear: "Clear",
      fields: {
        reason: "Reason",
        message: "Message",
        active: "Active",
        toDate: "until",
        fromDate: "from"
      }
    },
    competitions: {
      listFilter: {
        year: "Jahr",
        month: "Monat",
        country: "Land",
        zip: "Plz",
        level: "Level",
        matchType: "Match Art",
        club: "Verein",
        clearFilters: "Filter löschen",
        events: "Sonderveranstaltungen",
        paidFilter: "Zahlung ausständig",
        province: "Bundesland",
        viewType: "Ansichtstyp"
      },
      listPage: {
        event: "Veranstaltung",
        headline: "Aktuelle Bewerbe",
        buttonDetails: "zum Bewerb",
        eventButtonDetails: "zur Veranstaltung",
        resultList: "Zur Ergebnisliste",
        downloadCompetitionList: "Liste herunterladen",
        competitionTypeName: "Bewerbstype",
        rowsPerPage: "Zeilen pro Seite",
        division: "Division",
        squad: "Squad",
        squadStand: "Stand",
        startDateTime: "Startzeit",
        of: "von",
        filters: {
          dateFrom: "Von",
          dateTo: "Bis",
          club: "Verein"
        },
        matchDate: "Match Datum",
        squadDate: "Squad Datum",
        region: "Region",
        metchPlace: "Austragungsort",
        club: "Verein",
        matchName: "Match Name",
        level: "Level",
        matchType: "Match Art",
        stages: "Stages",
        registrationStartDate: "Anmeldung ab",
        freeSlots: "Freie Plätze",
        actions: "Aktionen",
        details: "Details",
        register: "Anmeldung",
        listView: "Listenansicht",
        listViewGuest: "Listenansicht (nur für registrierte Schützen verfügbar)"
      },
      editPage: {
        headline: "Bewerbe bearbeiten",
        competitionDateChanged: "Start-Datum geändert...",
        updateAllDates:
          "Soll das Match-Ende, der Anmeldezeitraum und das Start-Datum der Squads entsprechend aktualisiert werden?"
      },
      createPage: {
        headline: "Bewerbe erstellen"
      },
      resultPage: {
        headline: "Ergebnisliste",
        upcomingCompetitions: "Zu den aktuellen Bewerben",
        clearFilters: "Filter löschen"
      },
      name: "Bewerbe",
      uploadTypes: "Downloads",
      cancelButton: {
        cancel: "Aktiv: Absagen?",
        unCancel: "Abgesagt: Wieder aktivieren?"
      },
      cancelDialog: {
        header: "Bewerb absagen",
        undoHeader: "Absage aufheben",
        reallyCancel: "Wollen Sie den Bewerb wirklich absagen?",
        reallyUnCancel: "Wollen Sie die Absage wirklich aufheben‚?",
        cancel: "Abbrechen",
        doCancel: "Jetzt absagen",
        doUnCancel: "Absage aufheben"
      },
      fields: {
        paid: "Bezahlt",
        tabs: {
          notificationBlocks: "Informations-Blöcke",
          competition: "Bewerbinformation"
        },
        notificationBlocks: {
          COMPETITION_SIGN_ON_CONFIRMATION: "Anmeldebestätigung",
          COMPETITION_WAITING_LIST_CHANGE_INFORMATION:
            "Änderung eines Wartelisten-Platzes",
          COMPEITION_UPCOMING_INFORMATION: "Bevorstehender Bewerb",
          COMPETITION_SIGN_OFF_CONFIRMATION: "Abmeldung von einem Bewerb",
          COMPETITION_CANCELATION_CONFIRMATION: "Absage eines Bewerbes",
          COMPETITION_PAYMENT_RECEIVED: "Zahlung wurde empfangen"
        },
        club: {
          name: "Verein",
          id: "Verein"
        },
        type: {
          name: "Typ"
        },
        nominationFee: "Teilnahmegebühr",
        competitionDate: "Datum",
        date: "Start-Datum",
        endDate: "End-Datum",
        competitionHeader: "Bewerbserstellung",
        information: "Information",
        level: { id: "Level", name: "Level" },
        stages: "Stages",
        announcementLink: "Ausschreibung-LINK",
        clubWebsiteLink: "Vereinswebsite",
        address: "Adresse",
        city: "Stadt",
        zip: "Postleitzahl",
        googleMaps: "Google Maps Link",
        registrationStart: "Anmeldestart",
        registrationEnd: "Anmeldeende",
        competitionTypeName: "Bewerbstype",
        minimumShots: "Mindestschussanzahl",
        feeStatus: "Bezahlstatus",
        publicLink: "Öffentlicher Link",
        division_ids: "Division",
        factor_ids: "Factor",
        competitionClass_ids: "Berwerbsklasse",
        competitionType_ids: "Bewerbstype",
        squadHeader: "Squads",
        squadInformationDescription: "Squadinformation",
        signupForm: {
          signup: "Jetzt anmelden",
          header: "Anmeldung",
          waitingListSignup: "Anmeldung auf Warteliste",
          waitingListOnly:
            "Eine Anmeldung ist derzeit nur auf einen Wartelisten-Platz möglich.",
          gotWaitingList: "Wartelistenplatz erhalten!",
          gotStartPlace: "Startplatz erhalten!",
          timerPrefix: "Ein Platz ist noch",
          timerSuffix: " Sekunden für Dich reserviert.",
          timerInfo:
            "Schließe die Anmeldung schnell ab damit dieser erhalten bleibt."
        },
        competitionTypeId: "Bewerbs-Typ",
        contactHeader: "Kontaktperson",
        contact: {
          firstName: "Vorname",
          lastName: "Nachname",
          email: "Email",
          telephone: "Telefon"
        },
        addressHeader: "Austragungsort",
        country: "Land",
        state: "Vereins-Bundesland als Pflichtfeld in der Anmeldung anzeigen",
        googleMapsLink: "Google Maps-Link",
        dateHeader: "Datumsinformation",
        additionalInfo: "Zusatzinformation",
        multipleRegistrations: "Mehrfachanmeldung zulässig",
        paymentViaDeposit: "Bezahlung per Überweisung möglich",
        payWithWirecard: "Bezahlung per Wirecard möglich",
        nationalCompetition: "Landes- oder Staatsmeisterschaft",
        firstPaymentReminder: "Erste Zahlungserinnerung senden",
        secondPaymentReminder: "Zweite Zahlungserinnerung senden",
        autoSubscriptionCancelation:
          "Teilnehmer bei fehlendem Zahlungseingang automatisch abmelden",
        payOnTime: "Bezahlfrist",
        payOnSite: "Bezahlung vor Ort möglich",
        qualification: "Qualifikationsbewerb",
        unlisted: "Ungelisteter Bewerb",
        active: "Aktiv",
        NewCompetitionMailSent: "Neuer Bewerbs Mail bereits versandt.",
        viewType: {
          label: "Ansichtstyp",
          all: "Bewerb überall anzeigen",
          internal: "Bewerb nur intern anzeigen",
          external: "Bewerb nur extern anzeigen",
          main: "Bewerb nur in Hauptbewerbsliste anzeigen",
          unlisted: "Ungelisteter Bewerb"
        }
      }
    },
    squads: {
      copySquad: "Squad kopieren",
      removeSquad: "Squad entfernen",
      addStands: "Stände hinzufügen",
      fields: {
        name: "Name",
        information: "Information",
        squadSize: "Anzahl möglicher Teilnehmer",
        maxWaitingListSize: "Anzahl möglicher Wartelistenplätze",
        startDate: "Start Datum",
        startTime: "Startzeit",
        endTime: "Endzeit",
        resultListLink: "Ergebnisliste-LINK",
        requireCode: "nur mit Reservierungscode",
        division: "Division",
        factor: "Faktor",
        competitionClass: "Bewerbsklasse",
        stands: "Stände"
      }
    },
    competitionspub: {
      name: "Bewerbe",
      signupForm: {
        signup: "Jetzt anmelden",
        header: "Anmeldung",
        reservationCodeSignup:
          "Die Anmeldung zu diesem Bewerb ist noch nicht offen!",
        reservationCodeOnly:
          "Um sich vorab anmelden zu können, geben Sie bitte den Code ein, den Sie vom Vereins-Admin erhalten haben",
        nationalReservationCodeOnly:
          "Bitte verwenden Sie für die Anmeldung den Code den Sie erhalten haben.",
        nationalReservationCodeSignup:
          "Dieser Bewerb ist eine Landes- oder Staatsmeisterschaft und daher ist die Anmeldung nur mit einem Code vom Bundes- oder Landessportleiter möglich!",
        squadReservationCodeOnly:
          "Die Anmeldung ist nur mit einem Anmeldecode möglich",
        squadReservationCodeSignup:
          "Dies ist eine durch den Admin gesperrte Squad.",
        waitingListSignup: "Jetzt auf Warteliste anmelden",
        waitingListOnly: "Nur noch Wartelisten-Plätze frei!",
        squad: "Squad",
        firstName: "Vorname",
        lastName: "Nachname",
        email: "E-Mail-Adresse",
        registrationCode: "Reservierungscode",
        nationalRegistrationCode: "Code",
        divisions: "Division",
        factors: "Faktor",
        classes: "Bewerbsklasse",
        squadFull: "Ausgewählte Squad bereits voll.",
        waitingList: "Anmeldung auf Warteliste für mehrere Squads",
        waitingListForSquads: "Warteliste für mehrere Squads",
        club: "Verein",
        country: "Land",
        state: "Bundesland des Vereins",
        paymentMethod: "Bezahlart",
        cash: "Barzahlung",
        invoice: "Banküberweisung",
        User: "Schütze",
        squadStand: "Stand"
      }
    },
    competitionshow: {
      freePlaces: "Freie Plätze:",
      downloads: "Downloads",
      noPermitionToSignUp: "Sie haben keine Berechtigung zur Registrierung!",
      competitionRegistrationEnded: "Anmeldezeitraum bereits verstrichen.",
      competitionRegistrationEndedInfo:
        "Bitte wende dich wegen Änderungen deiner Anmeldung an den Vereins-Admin.",
      signUp: "Anmelden",
      information: "Beschreibung:",
      details: "Details:",
      links: "Links",
      competitionFull: "Keine freien Plätze im Bewerb verfügbar.",
      competitionCancelled: "Der Bewerb wurde abgesagt",
      competitionInactive: "Der Bewerb wurde gelöscht",
      squadFull: "Keine freien Plätze in der Squad verfügbar.",
      detailsTable: {
        clubName: "Veranstalter:",
        type: "Typ:",
        level: "Level:",
        stages: "Anzahl der Stages:",
        fee: "Teilnahmegebühr:",
        contactPerson: "Kontaktperson:",
        location: "Austragungsort:",
        googleMapsLink: "Google Maps Link",
        divisionen: "Divisionen:",
        classes: "Klassen:",
        factors: "Faktoren:",
        minimumShots: "Mindest Schussanzahl:",
        registrationStart: "Anmeldestart:",
        registrationEnd: "Anmeldeschluss:",
        feeStatus: "Bezahlstatus:",
        bankAccountInfos: "Bankdaten:",
        bic: "BIC:",
        iban: "IBAN:",
        freePlaces: "Freie Plätze:",
        eventType: "Veranstaltungstyp",
        freeWaitingListPlaces: "Freie Wartelistenplätze:"
      },
      announcementLink: "Ausschreibung-LINK",
      clubWebsiteLink: "Vereinswebsite",
      participants: "Teilnehmer",
      participantsName: "Name",
      participantsSquad: "Squad",
      participantsSquadStand: "Stand",
      participantsClass: "Klasse",
      participantsDivision: "Division",
      squads: "Squads",
      squad: {
        noPermitionToSignUp: "Sie haben keine Berechtigung zur Registrierung!",
        signUpTo: "Anmelden zu",
        signUpToOver: "Anmeldezeitraum vorbei",
        id: "Squad Nr.",
        annotations: "Anmerkungen / Informationen",
        startDate: "Start Datum",
        startTime: "Startzeit",
        freePlaces: "Freie Plätze",
        squadSize: "Squad-Größe",
        squadMember: "Teilnehmer:",
        squadMemberName: "Name",
        squadMemberDivision: "Division",
        squadMemberClass: "Klasse",
        squadMemberKey: "#",
        freePlacesInSquads: "Freie Plätze",
        waitingList: "Warteliste",
        waitingListOnly: "Nur noch Wartelisten-Plätze frei!",
        full: "Squad bereits voll"
      },
      resultListLink: "ONLINE-ERGEBNISLISTEN"
    },
    reservations: {
      fields: {
        user_ids: "Benutzer",
        competition_ids: "Bewerbe",
        division_ids: "Divisionen",
        competitionClass_ids: "Bewerbsklassen",
        factor_ids: "Faktoren",
        qualification: "Qualifikationsbewerb",
        maxReservations: "Max. Reservierungen",
        validUntil: "Gültig bis:"
      },
      createReservation: "Reservierung erstellen",
      editReservation: "Reservierung bearbeiten",
      listReservation: "Reservierungen",
      showReservation: "Reservierung"
    },
    misc: {
      tabs: {
        userRoleData: "Benutzerrolle",
        userRolePermissions: "Berechtigungen"
      },
      userRoleCategories: {
        cat_ADMINISTRATION: "Administration",
        cat_OTHER: "Andere Berechtigungen",
        cat_COMPETITIONS: "Competitions",
        cat_USER: "User",
        cat_: "View User",
        cat_CLUB: "Club"
      }
    },
    events: {
      createPage: {
        headline: "Veranstaltung erstellen"
      },
      editPage: {
        headline: "Veranstaltung bearbeiten"
      },
      fields: {
        firstPaymentReminder: "Erste Zahlungserinnerung senden",
        secondPaymentReminder: "Zweite Zahlungserinnerung senden",
        autoSubscriptionCancelation:
          "Teilnehmer bei fehlendem Zahlungseingang automatisch abmelden",
        tabs: {
          notificationBlocks: "Informations-Blöcke",
          competition: "Bewerbinformation"
        },
        notificationBlocks: {
          COMPETITION_SIGN_ON_CONFIRMATION: "Anmeldebestätigung",
          COMPETITION_WAITING_LIST_CHANGE_INFORMATION:
            "Änderung eines Wartelisten-Platzes",
          COMPEITION_UPCOMING_INFORMATION: "Bevorstehender Bewerb",
          COMPETITION_SIGN_OFF_CONFIRMATION: "Abmeldung von einem Bewerb",
          COMPETITION_CANCELATION_CONFIRMATION: "Absage eines Bewerbes",
          COMPETITION_PAYMENT_RECEIVED: "Zahlung wurde empfangen"
        },
        type: {
          name: "Typ"
        },
        club: "Verein",
        eventType: "Veranstaltungstyp",
        nominationFee: "Teilnahmegebühr",
        competitionDate: "Datum",
        competitionHeader: "Bewerbserstellung",
        date: "Datum",
        information: "Information",
        level: { id: "Level", name: "Level" },
        stages: "Stages",
        announcementLink: "Ausschreibung-LINK",
        clubWebsiteLink: "Vereinswebsite",
        address: "Adresse",
        city: "Stadt",
        zip: "Postleitzahl",
        googleMaps: "Google Maps Link",
        registrationStart: "Anmeldestart",
        registrationEnd: "Anmeldeende",
        competitionTypeName: "Bewerbstype",
        minimumShots: "Mindestschussanzahl",
        feeStatus: "Bezahlstatus",
        publicLink: "Öffentlicher Link",
        division_ids: "Division",
        factor_ids: "Factor",
        competitionClass_ids: "Berwerbsklasse",
        competitionType_ids: "Bewerbstype",
        squadHeader: "Squads",
        squadInformationDescription: "Squadinformation",
        signupForm: {
          signup: "Jetzt anmelden",
          header: "Anmeldung",
          waitingListSignup: "Anmeldung auf Warteliste",
          waitingListOnly:
            "Eine Anmeldung ist derzeit nur auf einen Wartelisten-Platz möglich."
        },
        competitionTypeId: "Bewerbs-Typ",
        contactHeader: "Kontaktperson",
        contact: {
          firstName: "Vorname",
          lastName: "Nachname",
          email: "Email",
          telephone: "Telefon"
        },
        addressHeader: "Austragungsort",
        country: "Land",
        googleMapsLink: "Google Maps-Link",
        dateHeader: "Datumsinformation",
        additionalInfo: "Zusatzinformation",
        multipleRegistrations: "Mehrfachanmeldung zulässig",
        paymentViaDeposit: "Bezahlung per Überweisung möglich",
        payWithWirecard: "Bezahlung per Wirecard möglich",
        nationalCompetition: "Landes- oder Staatsmeisterschaft",
        payOnTime: "Bezahlfrist",
        payOnSite: "Bezahlung vor Ort möglich",
        qualification: "Qualifikationsbewerb",
        state: "Vereins-Bundesland als Pflichtfeld in der Anmeldung anzeigen",
        unlisted: "Ungelisteter Bewerb",
        active: "Aktiv",
        eventName: "Veranstaltungsname"
      }
    }
  },
  competitionList: {
    cancelled: "",
    name: "Bewerbsname",
    clubName: "Veranstalter",
    city: "Stadt",
    address: "Adresse",
    competitionDate: "Veranstaltungsdatum",
    levelName: "Veranstaltungslevel",
    stages: "Veranstaltungsstages",
    competitionTypeName: "Veranstaltungstyp",
    country: "Region",
    menu: "",
    openDetailsButton: " ",
    titleClubAdmin: "Vereinsbewerbe",
    titleAdmin: "Alle Bewerbe",
    m: {
      cancelled: "Info:",
      name: "Bewerbsname:",
      clubName: "Veranstalter:",
      city: "Stadt:",
      address: "Adresse:",
      competitionDate: "Veranstaltungsdatum:",
      levelName: "Veranstaltungslevel:",
      stages: "Veranstaltungsstages:",
      competitionTypeName: "Veranstaltungstyp:",
      openDetailsButton: " ",
      menu: "Menü"
    },
    filters: {
      openFilter: "Bewerbe filtern",
      filtersActive: "Filter aktiv",
      competitionName: "Bewerbsname",
      year: "Jahr",
      month: "Monat",
      region: "Region",
      clubName: "Verein",
      level: "Level",
      competitionType: "Veranstaltungstyp"
    }
  },
  eventList: {
    titleClubAdmin: "Vereinsveranstaltungen",
    titleAdmin: "Alle Veranstaltungen"
  },
  clubList: {
    cancelled: "Info",
    name: "Veranstalter",
    publicLinkId: "Öffentlicher Link",
    zip: "Postleitzahl",
    contactFullName: "Kontakt",
    actions: "",
    city: "Stadt",
    address: "Adresse",
    competitionDate: "Veranstaltungsdatum",
    level: "Veranstaltungslevel",
    stages: "Veranstaltungsstages",
    competitionTypeName: "Veranstaltungstyp",
    openDetailsButton: "",
    imagePre: "Logo",
    m: {
      cancelled: "Info:",
      name: "Veranstalter:",
      publicLinkId: "Link:",
      zip: "Postleitzahl:",
      contactFullName: "Kontakt:",
      actions: "",
      city: "Stadt:",
      address: "Adresse:",
      competitionDate: "Veranstaltungsdatum:",
      level: "Veranstaltungslevel:",
      stages: "Veranstaltungsstages:",
      competitionTypeName: "Veranstaltungstyp:",
      openDetailsButton: "",
      imagePre: "Logo"
    }
  },
  shooterCompetitionList: {
    headerBookedCompetitions: "Angemeldete Bewerbe",
    headerFavouriteCompetitions: "Markierte Bewerbe",
    headerParticipatedCompetitions: "Absolvierte Bewerbe",
    waitingList: "Warteliste",
    downloadCompetitions: "Liste herunterladen"
  },
  eventCard: {
    registrationStart: "Anmeldebeginn",
    freePlaces: "Freie Plätze"
  },
  bulkDeleteWarningPopup: {
    title: "Ausgewählte Zeilen löschen?"
  },
  bulkCompetitionPopup: {
    title: "Ausgewählte Zeilen exportieren oder löschen?"
  },
  deleteWarningPopup: {
    title: "Wollen Sie das aktuelle Element wirklich löschen?"
  },
  deleteCompetitionWarningPopup: {
    title: "Wollen Sie den aktuellen Bewerb wirklich löschen?"
  },
  changelog: {
    title: "Entwicklungslog",
    features: "Änderungen/Features",
    fixes: "Bugfixes"
  },
  CONFIRM_REGISTRATION: {
    VERIFY_SUCCESS:
      "Ihre Registrierung wurde erfolgreich bestätigt! Sie können Sich nun annmelden.",
    GO_TO_LOGIN: "Jetzt anmelden",
    VERIFYING: "Registrierung wird bestätigt...",
    PAYMENT_INFORMATION:
      "Ihre Vorteile als registrierter Schütze und Zahlungsmodalitäten ersehen Sie bitte unter"
  },
  ERROR: {
    FIELD_MUST_HAVE_AT_LEAST_2_CHARACTERS:
      "Feld benötigt mindestens 2 Zeichen.",
    NO_RESERVATION_LIMIT: "Maximale Anzahl an Reservierungen fehlt",
    NO_VALID_UNTIL: "Gültigkeitsdatum fehlt",
    PAYMENTMETHOD_INVALID_FOR_CART:
      "Anmeldung fehlgeschlagen. Zahlungsmethode des Bewerbs stimmt nicht mit der Zahlungsmethode der Anmeldungen im Einkaufswagen überein.",
    PAYMENTMETHOD_MISSING: "Keine Zahlungsmethode ausgewählt.",
    CODE_USED: "Reservierungscode bereits verbraucht.",
    USED_CODE: "Reservierungscode bereits verbraucht.",
    INVALID_CODE: "Reservierungscode ungültig.",
    NO_USER: "Benutzer wurde nicht gefunden.",
    NO_EMAIL_ADDRESS: "E-mail wurde nicht gefunden.",
    Unauthorized: "Benutzername oder Kennwort ungültig.",
    Inactive_user: "Benutzer ist gesperrt.",
    Not_verified: "Benutzer Mail noch nicht bestätigt.",
    CLIENT_DOES_NOT_EXIST: "Client does not exist",
    PERMISSION_DENIED: "Sie sind für diese Aktion nicht berechtigt.",
    CLIENT_ALREADY_EXISTS: "Client already exists",
    INVALID_USERROLE: "Permission denied",
    USER_ALREADY_EXISTS:
      "Ein Benutzer mit diesem Benutzernamen existiert bereits.",
    USER_DOES_NOT_EXIST: "Der angegebene Benutzer existiert nicht",
    EMAIL_ALREADY_EXISTS:
      "Ein Benutzer mit dieser Email-Adresse existiert bereits.",
    USERPERMISSION_DOES_NOT_EXIST:
      "Die angegebene Berechtigung existiert nicht",
    USERROLE_DOES_NOT_EXIST: "Die angegebene Rolle existiert nicht",
    USERROLE_ALREADY_EXISTS: "Die angegebene Rolle existiert bereits",
    USERROLE_NAME_NOT_VALID: "Der angegebene Name ist nicht gültig",
    PERMISSION_DENIED_ROLE_NAME_RESERVED:
      "Der angegebene Name ist nicht gültig",
    PERMISSION_DENIED_ROLE_ADMIN_NOT_EDITABLE:
      "Diese Rolle ist nicht bearbeitbar",
    PERMISSION_DENIED_ROLE_GLOBAL_NOT_DELETEABLE:
      "Diese Rolle kann nicht gelöscht werden",
    PERMISSION_DENIED_ROLE_ADMIN_NOT_DELETEABLE:
      "Diese Rolle kann nicht gelöscht werden",
    PERMISSION_DENIED_ROLE_CLIENT_GLOBAL_NOT_DELETEABLE_BY_USER:
      "Diese Rolle kann nicht gelöscht werden",
    PERMISSION_DENIED_ROLE_NOT_DELETABLE:
      "Diese Rolle kann nicht gelöscht werden",
    CLUB_DOES_NOT_EXIST: "Der angegebene Verein existiert nicht",
    USER_SELF_DELETE: "Dieser Benutzer kann nicht gelöscht werden",
    CLUB_NAME_DOES_NOT_EXIST: "Vereinsname nicht angegeben.",
    ERROR: "Es ist ein Fehler aufgetreten.",
    COMPETITONCLASS_MISSING: "Klasse nicht ausgewählt",
    FACTOR_MISSING: "Faktor nicht ausgewählt",
    DIVISION_MISSING: "Division nicht ausgewählt",
    SQUAD_FULL:
      "Die ausgewählte Squad ist bereits voll, Anmeldung daher nicht möglich! Bitte wählen Sie eine andere Squad!",
    SQUAD_ONLY_WAITINGLIST_SPOTS:
      "Ausgewältes Squad hat nur mehr Wartelisteplätze verfügbar.",
    SQUAD_REQUIRE_CODE:
      "Die ausgewählte Squad ist durch den Admin per Code gesperrt.\nBitte wende dich wegen einem Wechsel in diese an den Admin des Vereins!",
    SQUAD_MISSING: "Squad nicht ausgewählt",
    COMPETITIONCLASS_MISSING: "Bewerbsklasse nicht ausgewählt",
    COMPETITION_MISSING: "Bewerb nicht ausgewählt",
    COMPETITION_DOESNT_EXIST: "Bewerb existiert nicht.",
    CLUB_MISSING: "Verein nicht ausgewählt",
    SHOOTERROLE_NOT_FOUND: "Rolle Schütze nicht definiert",
    EMAIL_FORMAT: "Keine gültige Email-Adresse",
    FIELD_IS_REQUIRED: "Eine verpflichtende Eingabe fehlt ",
    FIELD_IS_NULL: "Eine verpflichtende Eingabe fehlt",
    USERNAME_FIELD_MUST_HAVE_AT_LEAST_3_CHARACTERS:
      "Der Benutzername sollte mindestens 3 Zeichen aufweisen",
    PW_FIELD_MUST_HAVE_AT_LEAST_6_CHARACTERS:
      "Das Passwort muss mindestens 6 Zeichen lang sein",
    FIRST_NAME_FIELD_MUST_HAVE_AT_LEAST_2_CHARACTERS:
      "Der Vorname muss mindestens 2 Zeichen lang sein",
    LAST_NAME_FIELD_MUST_HAVE_AT_LEAST_2_CHARACTERS:
      "Der Nachname muss mindestens 2 Zeichen lang sein",
    REGISTRATION_ALREADY_STARTED:
      "Das Anmeldefenster hat schon begonnen. Absage wirklich durchführen?",
    SELECT_COMPETITION_TYPE: "Bewerbstyp zuerst auswählen.",
    NO_SQUAD_DEFINED: "Kein Squad angegeben.",
    CHECK_REGISTRATION_DATE: "Bitte prüfen Sie Ihre Datumsangaben.",
    SQUAD_COULD_NOT_BE_DELETED_IN_USE:
      "Squad kann nicht entfernt werden da sie in Verwendung ist.",
    SQUAD_COULD_NOT_BE_DELETED:
      "Squad kann nicht entfernt werden da sie in Verwendung ist.",
    ACCESS_ONLY_SHOOTER:
      "Diese Ansicht steht nur registrierten Schützen zur Verfügung.",
    ACCESS_DENIED:
      "Diese Ansicht steht Ihnen nicht zur Verfügung. Bitte wenden Sie sich bei Fragen an einen Administrator.",
    PREPARED_ORDER_NOT_FOUND:
      "Ihr reservierter Startplatz wurde auf Grund zu langer Wartezeit wieder freigeben.\nBitte schließen Sie dieses Fenster und melden Sie sich erneut an!",
    ORDER_ITEM_NOT_FOUND: "Fehler beim Laden der Anmeldung",
    FILETYPE_NOT_ALLOWED:
      "Dateityp wird nicht unterstützt. Bitte benutzen Sie .png, .jpg oder .jpeg",
    MULTIPLE_REGISTRATION:
      "Mehrfachanmeldung für diesen Bewerb sind unzulässig.",
    MULTIPLE_REGISTRATION_NOT_PAID:
      "Mehrfachanmeldung steht nur für bezahlte registrierte Schützen zu verfügung",
    PREMIUM_FEATURE:
      "Mehrfachanmeldung nur für registrierte bezahlte Schützen zulässig.",
    TOO_EARLY_TO_DELETE:
      "Anmeldungen zu diesem Benutzer sind noch offen oder erst kürzlich abgeschlossen",
    FACTOR_NOT_IN_COMPETITION: "Squad Faktor ist nicht im Bewerb aktiv",
    DIVISION_NOT_IN_COMPETITION: "Squad Division ist nicht im Bewerb aktiv",
    COMPETITIONCLASS_NOT_IN_COMPETITION:
      "Squad Bewerbskalsse ist nicht im Bewerb aktiv",
    SQUAD_STAND_SIZE_NOT_MATCH:
      "Anzähl möglicher Teilnehmer unterscheidet sich von der Anzahl an Ständen",
    SQUAD_STAND_NOT_FOUND: "Der Stand ist bereits in Verwendung",
    SQUAD_STAND_WAITINGLIST_SPOT:
      "Auf Wartelistenplätze kann kein Stand ausgewählt werden"
  },
  countries: {
    at: "Österreich",
    de: "Deutschland"
  },
  ROLE_ADMIN: "System Administrator",
  ROLE_CLIENT_ADMIN: "Mandanten Administrator",
  PAYMENTMETHOD_REQUIRED: "Zahlungsart benötigt.",
  IS_ADMIN: "Ist Administrator",
  IS_CLIENT_ADMIN: "Ist Mandanten Administrator",
  IS_CLUB_ADMIN: "Ist Vereins-Admin",
  IS_PORTAL_ADMIN: "Ist Portal-Administrator",
  PUBLIC_COMPETITION_LIST_VIEW: "Kann öffentliche Bewerbsliste sehen",
  PUBLIC_COMPETITION_REGISTRATION: "Kann sich für öffentliche Bewerbe anmelden",
  USER_PROFILE_VIEW: "Kann Benutzerfrofil sehen",
  USER_PROFILE_EDIT: "Kann Benutzerfrofil bearbeiten",
  USER_CREATE: "Kann Benutzer erstellen",
  USER_EDIT: "Kann Benutzer bearbeiten",
  USER_DELETE: "Kann Benutzer löschen",
  USER_LIST: "Kann Benutzerliste sehen",
  USER_VIEW: "Kann Benutzer sehen",
  CLUB_LIST: "Kann Vereinsliste sehen",
  CLUB_EDIT: "Kann Vereine bearbeiten",
  CLUB_VIEW: "Kann Vereine sehen",
  CLUB_CREATE: "Kann Vereine erstellen",
  COMPETITION_CREATE: "Kann Bewerbe erstellen",
  COMPETITION_EDIT: "Kann Bewerbe bearbeiten",
  COMPETITION_LIST: "Kann Bewerbsliste sehen",
  COMPETITION_VIEW: "Kann Bewerbe sehen",
  ROLE_USER: "Schütze",
  ROLE_GUEST: "Gast",
  ROLE_PORTAL_ADMIN: "Portal Admin",
  ROLE_SYSTEM_ADMIN: "System Admin",
  ROLE_CLUB_ADMIN: "Vereins Admin",
  IS_NOT_ACTIVE: "Gesperrt",
  IS_NOT_ENABLED: "Nicht verifiziert",
  FIELD_IS_REQUIRED: "Pflichtfeld",
  PW_MUST_MATCH: "Die Passwörter stimmen nicht überein",
  PW_MIN_6_MAX_128_1_UPPER_1_LOWER_1_NUMERIC:
    "Bitte geben Sie mindestens 6 Zeichen ein, davon mindestens ein Groß-, ein Kleinbuchstabe und mindestens eine Ziffer.",
  USER_ALREADY_EXISTS:
    "Ein Benutzer mit diesem Benutzernamen existiert bereits.",
  EMAIL_ALREADY_EXISTS:
    "Ein Benutzer mit dieser Email-Adresse existiert bereits.",
  VERIFY_ERROR: "Ihre Registrierung konnte leider nicht bestätigt werden.",
  NO_SQUAD_DEFINED: "Kein Squad angegeben.",
  FIELD_IS_REQUIRED_IF_BANK_NAME_IS_FILLED:
    "Das Feld ist erforderlich, wenn der Bankname ausgefüllt ist.",
  FIELD_IS_REQUIRED_IF_BANK_IBAN_IS_FILLED:
    "Das Feld ist erforderlich, wenn die IBAN gefüllt ist.",
  IS_CANCELLED: "abgesagt",
  NO_ITEMS_IN_CART: "Sie haben keine Buchungen im Warenkorb.",
  SUCCESS_ORDER:
    "Ihre Buchung wurde soeben durchgeführt. Sie erhalten in Kürze eine Bestätigungsemail.",
  SUCCESS_ORDER_ITEM_EDIT: "Die Änderungen wurden erfolgreich gespeichert.",
  SUCCESS_ORDER_ITEM_CANCEL:
    "Sie haben sich erfolgreich vom Bewerb abgemeldet.",
  ONLY_ONE_COMPETITION_FOR_GUEST:
    "Gäste können nur eine Anmeldung auf einmal vornehmen.",
  SUCCESS_PAY: "Anmeldungen wurden bezahlt.",
  SUCCESS_UNPAY: "Anmeldung wurde auf nicht bezahlt gesetzt.",
  SUCCESS_CANCEL: "Schütze wurde abgemeldet.",
  SUCCESS_SHOOTER_CANCEL: "Ihre Abmeldung wurde erfolgreich durchgeführt.",
  SUCCESS_UNCANCEL: "Stornierung wurde aufgehoben.",
  CODE_USED: "Reservierungscode bereits verbraucht.",
  USED_CODE: "Reservierungscode bereits verbraucht.",
  INVALID_CODE: "Reservierungscode ungültig.",
  NO_RESERVATION_LIMIT: "Keine maximale Reservierungsanzahl",
  NO_VALID_UNTIL: "Kein Gültigkeitszeitraum angegeben",
  ADDED_TO_CART: "Anmeldung wurde in den Einkaufswagen gelegt.",
  NO_DOWNLOADS_AVAILABLE: "Keine Dateien verfügbar",
  MUST_START_WITH_HTTP_OR_HTTPS: "Der Link muss mit http oder https beginnen"
};
