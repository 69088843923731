import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import PropTypes from "prop-types";
import withMobileDialog from "@material-ui/core/withMobileDialog";

const styles = {
  msg: {
    marginTop: "10px",
    minWidth: "350px",
    whiteSpace: "pre-line"
  }
};

let GenericPopup = props => {
  const {
    handleConfirm,
    handleClose,
    open,
    title,
    message,
    confirmText,
    cancelText,
    fullScreen
  } = props;

  return (
    <Dialog open={open} fullScreen={fullScreen}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent style={styles.msg}>{message}</DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {cancelText}
        </Button>
        <Button onClick={handleConfirm} color="primary" autoFocus>
          {confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

GenericPopup.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  open: PropTypes.bool,
  title: PropTypes.string,
  message: PropTypes.string,
  confirmText: PropTypes.string,
  cancelText: PropTypes.string
};

export default withMobileDialog()(GenericPopup);
